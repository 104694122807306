import { Box, Divider, Typography } from "@mui/material";
import { CartDto } from "dto/sale/cart.dto";
import { useCart } from "hooks/useCart";
import { useCountry } from "hooks/useCountry";
import { useLabel } from "hooks/useLabel";
import React from "react";

const TotalPriceBlock: React.FC = () => {
  const { LL } = useLabel();
  const { currencyCode, currencyRate } = useCountry();
  const { cartObjects, deliveryMethodOffer } = useCart();

  if (!cartObjects) return null;
  if (!cartObjects.length) return null;
  const total = CartDto.calculateTotalPrice(cartObjects, currencyRate);
  const totalPriceDelivery =
    deliveryMethodOffer && deliveryMethodOffer.real_price
      ? parseFloat(deliveryMethodOffer.real_price.toString())
      : null;
  const totalPriceOrder = CartDto.calculateTotalPriceOrder(
    CartDto.calculateTotalPriceWithoutCurrency(cartObjects),
    totalPriceDelivery,
    currencyRate
  );
  return (
    <Box
      sx={{
        width: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "end",
        alignItems: "end",

        pl: { xs: 0, md: 4 },
      }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          gap: 1,
        }}>
        <Typography>{LL("total_price")}</Typography>
        <Typography
          sx={{ fontWeight: "bold" }}>{`${total} ${currencyCode}`}</Typography>
      </Box>
      {deliveryMethodOffer && totalPriceOrder ? (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              gap: 1,
            }}>
            <Typography>{LL("delivery_price")}</Typography>
            <Typography
              sx={{
                fontWeight: "bold",
              }}>{`${totalPriceDelivery} ${currencyCode}`}</Typography>
          </Box>
          <Box sx={{ px: 3, py: 1 }}>
            <Divider
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                width: "auto",
              }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              gap: 1,
            }}>
            <Typography>{LL("total_price_order")}</Typography>
            <Typography
              sx={{
                fontWeight: "bold",
              }}>{`${totalPriceOrder} ${currencyCode}`}</Typography>
          </Box>
        </Box>
      ) : (
        <Typography sx={{ fontWeight: "bold" }}>
          {LL("delivery_is_unavailable")}
        </Typography>
      )}
    </Box>
  );
};

export { TotalPriceBlock };

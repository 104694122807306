import * as React from "react";
import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonAboutUs: React.FC<SkeletonProps> = ({ lines }) => {
  return (
    <Box className="mainBanner" position="relative">
      <Skeleton variant="rectangular" sx={{ height: "100%" }} />
      <Box
        position="absolute"
        top={0}
        left={0}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}>
        <Skeleton
          variant="text"
          sx={{ width: { xs: "300px", md: "500px" }, fontSize: "30px", mt: 4 }}
        />
        <Skeleton
          variant="text"
          sx={{
            width: { xs: "310px", md: "630px" },
            fontSize: { xs: "34px", md: "48px" },
          }}
        />
        <Skeleton
          variant="text"
          sx={{
            width: { xs: "290px", md: "590px" },
            fontSize: { xs: "14px", md: "16px" },
          }}
        />
        <Skeleton
          variant="text"
          sx={{
            width: { xs: "282px", md: "570px" },
            fontSize: { xs: "14px", md: "16px" },
          }}
        />
        <Skeleton
          variant="text"
          sx={{
            width: { xs: "270px", md: "600px" },
            fontSize: { xs: "14px", md: "16px" },
          }}
        />
        <Skeleton
          variant="text"
          sx={{
            width: { xs: "290px", md: "590px" },
            fontSize: { xs: "14px", md: "16px" },
          }}
        />
        <Skeleton
          variant="rectangular"
          sx={{
            width: { xs: "150px", md: "200px" },
            height: { xs: "34px", md: "48px" },
            mt: 4,
          }}
        />
      </Box>
    </Box>
  );
};

export { SkeletonAboutUs };

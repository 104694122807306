import React, { createContext, useCallback, useEffect, useState } from "react";
import IProvider from "interfaces/provider.interface";
import { Loading } from "components/elements/loading/Loading";
import { LocalStorageTools } from "api/localstorage.api";
import { ConfigDto } from "dto/static/config.dto";
import {ConfigService} from "services/system/config.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/utils/common.tool";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { ConfigTypes } from "tools/types/configtypes";
import { Config } from "tools/utils/config";


type Props = {
  CC: (str: string,value?:string) => string;
};
export const ConfigContext = createContext<Props>({
  CC: () => "",
});

const getLocalConfigs = (): Array<ConfigDto> => {
  const configs = LocalStorageTools.getObject("configs");
  if (!configs) return [];
  return configs;
}

var configsLocalGlobal: any = getLocalConfigs();
var configsTmp: Array<ConfigDto> = [];
var savedConfigs: Array<string> = [];

const service = new ConfigService();

export const ConfigProvider: React.FC<IProvider> = ({ children }) => {
  
  const [loading, setLoading] = useState(true);
  const [hashLocal, setHashLocal] = useState<string>(getLocalHash());
  
  const [hash, setHash] = useState<string>("");

  const CC = (identifier: string,valueConfig?:string): string => {
    if(!valueConfig) valueConfig = "10";
    if (configsLocalGlobal === false) return valueConfig;
    const preparedIdentifier = CommonTools.prepareLabelIdentifier(identifier);

    let value = "";
    let founded = false;
    
    for (const item of configsLocalGlobal) {
      if (item.identifier === preparedIdentifier) {
        founded = true;
        value = item.value;
        break;
      }
    }
    if (founded) {
      value = value ?? valueConfig;
      return value;
    }

    saveConfig(preparedIdentifier,valueConfig);
    return valueConfig;
  };
  

  const getHash = () => {
    service.getHash(handleGetHash, {});
  };

  const handleGetHash = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.error) return;
    if (!result.obj) return;
    const hash = CommonTools.processObjectField(result, ["obj", "hash"]);
    setHash(hash);
  };

  useEffect(() => {
    getHash();
    Config.setCC(CC);
  }, []);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!hash) loading = true;
    setLoading(loading);
  }, [hash]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const loadConfigs = (pageNumber?: number) => {
    pageNumber = pageNumber ?? 1;
    const req = new RequestListDTO();
    req.page = pageNumber;
    req.onpage = 50;
    const filter = new RequestFilterDTO();
    filter.field = "type";
    filter.values = [ConfigTypes.SITE.toString()];
    req.filters = [filter];
    
    // logger("loadConfigs", pageNumber);
    service.getConfigListSite(handleGetList, {}, req);
  };

  const handleGetList = (result: ResultListDTO<ConfigDto>) => {
    if (!result) return;
    if (result.error) return;
    if(!result.objects) return;
    if (!result.requestinfo) return;
    if (!result.requestinfo.page) return;
    if (!result.totalpages) return;
    configsTmp = [...configsTmp, ...result.objects];
    if (result.requestinfo.page < result.totalpages) {
      loadConfigs(result.requestinfo.page + 1);
    } else {
      processConfigs(configsTmp);
    }
  };

  const processConfigs =  (configs: Array<ConfigDto>) => {
    configsLocalGlobal = configs;
    // logger("processConfigs", configs);
    LocalStorageTools.saveObject("configs", configs);
    LocalStorageTools.saveValue("config_hash", hash);
    setHashLocal(hash);
    
  };

  const saveConfig = (identifier: string,value?:string) => {
    if (savedConfigs.includes(identifier)) return;
    savedConfigs.push(identifier);
    // logger("saveConfig", identifier);

    if (!Array.isArray(configsLocalGlobal)) {
      configsLocalGlobal = [ConfigDto.getLocal(identifier,value)];
    } else {
      configsLocalGlobal = [
        ...configsLocalGlobal,
        ConfigDto.getLocal(identifier,value),
      ];
    }
    
    service.getConfig(identifier, false, {});
  };


  const checkConfigHash = useCallback(() => {
    if (!hash) return;
    if (hash === hashLocal) return;
    else loadConfigs();
  }, [hash, hashLocal]);

  useEffect(() => {
    checkConfigHash();
  }, [checkConfigHash]);

  const value = {
    CC,
  };

  return loading ? (
    <Loading />
  ) : (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

const getLocalHash = (): string => {
  const hash = LocalStorageTools.getValue("config_hash");
  if (!hash) return "";
  return hash;
};


import Idto from "interfaces/idto.interface";

export class IPInfo implements Idto {
  
  country: string;
  

  constructor(obj?: any) {
    
    this.country = obj?.country || "";
    
  }
}

import React from "react";
import Pagination from "@mui/material/Pagination";

type Props = {
  total: number;
  page: number;
  setPage: (page: number) => void;
  totalPage: number;
};

const MyPagination: React.FC<Props> = ({ total, page, setPage, totalPage }) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if(!total) return null;
  return (
    <Pagination
      count={totalPage}
      page={page}
      onChange={handleChange}
      color="primary"
    />
  );
};

export { MyPagination };

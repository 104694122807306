import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";

import { ConfigTypes } from "tools/types/configtypes";

export class ConfigDto implements Idto {
  id?: number | string;
  identifier?: string;
  type?: number;
  value?: string;
  comment?: string;

  constructor(
    id?: number | string,
    identifier?: string,
    type?: number,
    value?: string,
    comment?: string
  ) {
    this.id = id || 0;
    this.identifier = identifier || "";
    this.type = type || ConfigTypes.SITE;
    this.value = value || "";
    this.comment = comment || "";
  }
  

  static getLocal(identifier: string, value?: string): ConfigDto {
    if (!value) value = "10";
    return new ConfigDto(
      0,
      CommonTools.getPrefixLabel(identifier),
      ConfigTypes.SITE,
      value,
      ""
    );
  }
}

import React, { useEffect, useState } from "react";
import { Box, Link, LinkProps } from "@mui/material";
import { RouteTools } from "tools/utils/route.tool";
import { CommonTools } from "tools/utils/common.tool";
import { logger } from "tools/utils/logger";

type Props = LinkProps & {
  children: React.ReactNode;
  href: string;
  _hstate?: any;
  _mainurl?: string;
  buttonTheme?: string;
};

const NavButton: React.FC<Props> = ({
  children,
  href,
  _hstate,
  _mainurl,
  buttonTheme,
  ...props
}) => {
  const [hstate, setHstate] = useState({});
  const [mainurl, setMainUrl] = useState("");

  useEffect(() => {
    const h: any = _hstate != undefined ? _hstate : {};
    setHstate(h);
  }, [_hstate]);

  useEffect(() => {
    const m = _mainurl != undefined ? _mainurl : href;
    setMainUrl(m);
  }, [_mainurl]);

  const handleClick = (e: any) => {
    if (CommonTools.isExternalUrl(href)) return;
    e.stopPropagation();
    e.preventDefault();

    const h: any = hstate ? hstate : {};
    h._mainurl = mainurl;
    let anchor = "";
    if (h.hasOwnProperty("anchor") && h.anchor) anchor = h.anchor;

    RouteTools.setHistory(href, h, anchor);
  };

  return (
    <Box className={buttonTheme}>
      <Link href={href} onClick={handleClick} {...props}>
        {children}
      </Link>
    </Box>
  );
};

export { NavButton };

import { Box, Typography } from "@mui/material";
import { Image } from "components/elements/display/Image";
import { AdvertisementDto } from "dto/static/advertisement.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  item: AdvertisementDto;
  index: number;
};

const AdvertisementLoginBenefits: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    <Box className="advertisementLoginBenefits">
      <Image
        url={CommonTools.processObjectField(item, ["_filecdnurl"])}
        typeMeasure="pixel"
        sizeInPx={{
          width: item.imgwhidth ? item.imgwhidth : 25,
          height: item.imgheight ? item.imgheight : 25,
        }}
        withLinK={false}
      />
      <Typography className="advertisementLoginBenefitsText">
        {CommonTools.processObjectField(item, ["_name"])}
      </Typography>
    </Box>
  );
};

export { AdvertisementLoginBenefits };

import { Box, Divider } from "@mui/material";
import { SorterBlock } from "components/elements/sorter/SorterBlock";
import { Filters } from "components/product/search/Filters";
import { ProductList } from "components/product/search/ProductList";
import { ProductPagination } from "components/product/search/ProductPagination";
import { TopBlockSearch } from "components/product/search/TopBlockSearch";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { useConfig } from "hooks/useConfig";
import { useSearch } from "hooks/useSearch";
import { IPage } from "interfaces/page.interface";
import React, { useEffect } from "react";
import { CommonTools } from "tools/utils/common.tool";
import { useResponsive } from "hooks/useResponsive";

const SearchPage: React.FC<IPage> = ({ currentRoute }) => {
  const mainUrl = CommonTools.processObjectField(currentRoute, ["url"]).split(
    "?"
  )[0];
  const { CC } = useConfig();
  const { downSM } = useResponsive();
  const sortIdentifiers = CC(
    "category_product_sort_identifiers",
    "order,price"
  );
  const {
    getDefaultFilters,
    getProducts,
    handleSearchFilter,
    setSortCriteria,
    sortCriteria,
  } = useSearch();

  useEffect(() => {
    handleSearchFilter();
  }, [handleSearchFilter]);

  useEffect(() => {
    getDefaultFilters(currentRoute);
  }, [getDefaultFilters]);

  useEffect(() => {
    if (!mainUrl) return;
    getProducts(
      mainUrl,
      CommonTools.processObjectField(currentRoute, ["_mainobject"])
    );
  }, [getProducts]);

  if (!currentRoute) return null;

  return (
    <Box>
      <Box className="productTopBlock">
        <TopBlockSearch />
        <Box
          className={downSM ? "flexJustifyCenter" : "flexJustifyEnd"}
          sx={{ gap: 2 }}>
          <Box>
            <Filters />
          </Box>
          <Divider
            className="verticalDivider"
            orientation="vertical"
            flexItem
          />
          <Box>
            <SorterBlock
              setSortCriteria={setSortCriteria}
              sortCriteria={sortCriteria}
              fields={RequestSortCriteriaDTO.prepareIdentifiers(
                sortIdentifiers
              )}
            />
          </Box>
        </Box>
      </Box>
      <ProductList />
      <Box>
        <ProductPagination />
      </Box>
    </Box>
  );
};

export { SearchPage };

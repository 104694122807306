import { Link, Typography } from "@mui/material";

import { useLabel } from "hooks/useLabel";
import { usePage } from "hooks/usePage";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";

const LinkToCookiePolicy: React.FC = () => {
  const id = Config.ID_COOKIE_POLICY_PAGE;
  const { object, loading } = usePage(id, true, "link_to_cookie_policy");
  const { LL } = useLabel();
  if (!id) return null;
  if (loading) return null;
  if (!object) return null;
  if (!CommonTools.processObjectField(object, ["_urlObj", "urlfull"]))
    return null;
  return (
    <Link
      href={CommonTools.processObjectField(object, ["_urlObj", "urlfull"])}
      sx={{ color: "black" }}
      target="_blank">
      <Typography className="linkCookiesText">{LL("cookie_policy")}</Typography>
    </Link>
  );
};

export { LinkToCookiePolicy };

import React from "react";
import { MySelect } from "./MySelect";
import SelectOptions from "dto/app/selectoptions.dto";

type Service = {
  GA: (type: string) => Array<SelectOptions>;
};
type Props = {
  type?: string;
  label?: string;
  setObjField: (field: string, value: any) => void;
  value: string | number | undefined;
  field?: string;
  service: Service;
  textColor?: string;
  selectTheme?: any;
  maxWidth?: string;
};
const StaticSelect: React.FC<Props> = ({
  type = "general",
  label = "select",
  setObjField,
  value,
  field = "type",
  service,
  selectTheme,
  maxWidth,
}) => {
  return (
    <MySelect
      options={service.GA(type)}
      _label={label}
      setObjectField={setObjField}
      value={value}
      field={field}
      selectTheme={selectTheme}
      maxWidth={maxWidth}
    />
  );
};

export { StaticSelect };


import { createTheme } from "@mui/material/styles";

const theme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#E2DBA3',
      },
      secondary: {
        main: '#ff002a',
      },
      error: {
        main: '#e53935',
      },
      background: {
        default: '#ffffff',
        paper: '#ffffff',
      },
      text: {
        // primary: 'rgba(5, 19, 36, 0.9)',
        primary: 'rgba(0, 0, 0, 1)',

        secondary: 'rgba(5, 19, 36, 0.6)',
        disabled: 'rgba(5, 19, 36, 0.4)',
      },
    },
    

    typography: {
      // fontFamily: 'Open Sans',
      fontSize: 13,
      htmlFontSize: 17,
      h1: {
        fontSize: '2rem',
        fontWeight: 700,
      },
      h2: {
        fontSize: '1.8rem',
        fontWeight: 600,
      },
      h3: {
        fontSize: '1.6rem',
        fontWeight: 600,
      },
      h4: {
        fontSize: '1.4rem',
        fontWeight: 600,
      },
      h5: {
        fontSize: '1.4rem',
        fontWeight: 600,
      },
      h6: {
        fontSize: '1.2rem',
        fontWeight: 600,
      },
      caption: {fontSize:12, textTransform:'uppercase', fontWeight:500, letterSpacing: '1px'},

      overline:{sontSize:12}
      
    },

    // breakpoints: {
    //   values: {
    //     xs: 0,
    //     sm: 600,
    //     md: 960,
    //     lg: 1280,
    //     xl: 1920,
    //     int: 1100,

    //   },
    // },
  },
);

export default theme;
import React from "react";

import { SocialPageDTO } from "dto/static/socialpage.dto";
import { Box, Link } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { Image } from "components/elements/display/Image";

type Props = {
  item: SocialPageDTO;
};

const SocialPageItem: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    <Box>
      <Link
        href={CommonTools.processObjectField(item, ["url"])}
        target="_blank"
        rel="noreferrer">
        <Image
          url={CommonTools.processObjectField(item, [
            "_galleryDefault",
            "cdnurl",
          ])}
          typeMeasure="pixel"
          sizeInPx={{ width: 25, height: 25 }}
          withLinK={false}
        />
      </Link>
    </Box>
  );
};
export { SocialPageItem };

import { GalleryDto } from "dto/system/gallery.dto";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { OfferDto } from "./offer.dto";

export class ProductDto implements Idto {
  id?: number | string;
  idcategory?: number;
  categorycode?: string;

  order?: number;
  status?: number;

  refcode?: string;

  minq?: number;
  defaultq?: number;
  maxq?: number;

  avorder?: number;
  recomendat?: number;
  showonhome?: number;

  _idlang?: number | string;

  _name?: string;
  _title?: string;
  _slug?: string;

  _description?: string;
  _shortdescription?: string;
  _metakey?: string;
  _metadescription?: string;

  _galleryDefault?: GalleryDto;
  _galleryActiveList?: GalleryDto[];

  defaultOfferObj?: OfferDto;
  _canspeedcart?: number;

  constructor(
    _idlang?: number | string,
    id?: number | string,
    order?: number,
    status?: number,
    refcode?: string,
    minq?: number,
    defaultq?: number,
    maxq?: number,
    avorder?: number,
    recomendat?: number,
    showonhome?: number,
    _name?: string,
    _title?: string,
    _slug?: string,
    _description?: string,
    _shortdescription?: string,
    _metakey?: string,
    _metadescription?: string
  ) {
    this.id = id || 0;

    this.order = order || 0;

    this.status = status || Status.ACTIVE;
    this._idlang = _idlang || 0;
    this._name = _name || "";
    this.refcode = refcode || "";
    this.minq = minq || 0;
    this.defaultq = defaultq || 0;
    this.maxq = maxq || 0;
    this.avorder = avorder || 0;
    this.recomendat = recomendat || 0;
    this.showonhome = showonhome || 0;
    this._title = _title || "";
    this._slug = _slug || "";
    this._description = _description || "";
    this._shortdescription = _shortdescription || "";
    this._metakey = _metakey || "";
    this._metadescription = _metadescription || "";
  }

  static getGalleryCdns = (product: ProductDto): string[] => {
    const gallery = product._galleryActiveList || [];
    return gallery
      .filter((item) => item.isdefault !== 1)
      .map((item) => item.cdnurl ?? "")
      .filter((item) => item !== "");
  };
}

import { GeneralSelect } from "components/elements/select/GeneralSelect";
import { CountryDto } from "dto/static/country.dto";
import React from "react";

type Props = {
  objects: Array<CountryDto>;
  selectedCountryCode: string;
  handleCountryChange: (code: string) => void;
  showLabel?: boolean;
  color?: string;
  textColor?: string;
  selectTheme?: any;
};
const CountrySelect: React.FC<Props> = ({
  objects,
  selectedCountryCode,
  handleCountryChange,
  showLabel = true,
  selectTheme,
}) => {
  return (
    <GeneralSelect
      options={CountryDto.parseToSelectOptions(objects, "code2")}
      value={selectedCountryCode}
      setValue={handleCountryChange}
      _label="country"
      fullWidth
      showLabel={showLabel}
      selectTheme={selectTheme}
    />
  );
};

export { CountrySelect };

import { Box, Typography } from "@mui/material";
import { AttachmentDto } from "dto/system/attachment.dto";
import React from "react";
import { AttachmentItem } from "./AttachmentItem";
import { useLabel } from "hooks/useLabel";

type Props = {
  objects?: Array<AttachmentDto>;
};

const AttachmentList: React.FC<Props> = ({ objects }) => {
  const { LL } = useLabel();
  const processItem = (item: AttachmentDto, index: number) => {
    if (!item) return null;
    return <AttachmentItem item={item} key={index} />;
  };
  if (!objects) return null;
  if (!objects.length) return null;
  return (
    <Box className="attachmentStyle">
      <Typography className="textStyleAttachment">
        {LL("staticAttachments")}
      </Typography>
      <Box className="fileAttachment">
        {objects.map((item, index) => processItem(item, index))}
      </Box>
    </Box>
  );
};

export { AttachmentList };

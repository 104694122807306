import React from "react";
import { Box, Grid } from "@mui/material";

import { ProductDto } from "dto/product/product.dto";
import { useCategoryProduct } from "hooks/useCategoryProduct";
import { ProductItem } from "./ProductItem";
import { NoResult } from "components/elements/display/NoResult";
import { SkeletonProductList } from "components/elements/skeleton/product/SkeletonProductList";

const ProductList: React.FC = () => {
  const { productList, totalPagesProducts, totalProducts, loadingListProduct } =
    useCategoryProduct();

  const processItem = (item: ProductDto, index: number) => {
    if (!item) return null;
    return (
      <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
        <ProductItem key={index} item={item} />
      </Grid>
    );
  };
  if (loadingListProduct)
    return (
      <Box sx={{ mt: 3 }}>
        <SkeletonProductList lines={6} />
      </Box>
    );
  if (!productList) return null;
  if (totalPagesProducts === -1) return null;
  if (totalProducts === -1) return null;
  if (!totalProducts) return <NoResult identifier={"products"} />;
  return (
    <Box className="productList">
      <Grid container spacing={2} sx={{ maxWidth: "1920px" }}>
        {productList.map((item: ProductDto, index: number) =>
          processItem(item, index)
        )}
      </Grid>
    </Box>
  );
};

export { ProductList };

import React from "react";

import { ProductDto } from "dto/product/product.dto";
import { Box, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { OfferBlock } from "./OfferBlock";
import { ProductFilterItemDictionar } from "components/product/productfilter/ProductFilterItemDictionar";

type Props = {
  item: ProductDto;
};

const ProductShortInfo: React.FC<Props> = ({ item }) => {
  return (
    <Box>
      <Box>
        <Typography className="productShortInfoCode">
          {CommonTools.processObjectField(item, ["refcode"])}
        </Typography>
      </Box>
      <Box>
        <Typography className="productShortInfoTitle">
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography className="productShortInfoShortInfo">
          {CommonTools.processObjectField(item, ["_shortdescription"])}
        </Typography>
      </Box>
    </Box>
  );
};
export { ProductShortInfo };

import React from "react";
import { Box, Typography } from "@mui/material";
import { Logo } from "components/general/Logo";
import { useLabel } from "hooks/useLabel";
import { MyButton } from "components/elements/button/MyButton";
import bgImage from "assets/images/bgCountryBlock.svg";
import { CountrySelect } from "./CountrySelect";
import { CountryDto } from "dto/static/country.dto";
import { SelectSiteLanguage } from "components/static/language/SelectSiteLanguage";
import { useLanguage } from "providers/LanguageProvider";
import { SelectCurrency } from "components/static/currency/SelectCurrency";
import { CurrencyDto } from "dto/static/currency.dto";

type Props = {
  value: string;
  processInfo: (
    countryCode: string,
    languageCode: string,
    currencyId: string
  ) => void;
  setValue: (values: string) => void;
  objectsCountry: Array<CountryDto>;
  selectCurrencyId: (currencyId: string) => void;
  objectsCurrency: CurrencyDto[];
  currencyId: string;
};
const CountryBlock: React.FC<Props> = ({
  value,
  processInfo,
  setValue,
  objectsCountry,
  selectCurrencyId,
  objectsCurrency,
  currencyId,
}) => {
  const { LL } = useLabel();
  const { selectLanguage, selectedLanguageCode } = useLanguage();
  const onClick = () => {
    processInfo(value, selectedLanguageCode, currencyId);
  };
  return (
    <React.Fragment>
      <Box className="countryBlockMask " />
      <Box className="flexAlignColumnCountry">
        <Box>
          <Logo width={297} height={178} />
        </Box>
        <Box mt={6}>
          <Typography className="welcomeText">{LL("welcome_text")}</Typography>
        </Box>
        <Box mt={4}>
          <Typography className="selectCountry">
            {LL("choose_country")}
          </Typography>
        </Box>
        <Box className="selectSize">
          <CountrySelect
            showLabel={false}
            objects={objectsCountry}
            selectedCountryCode={value}
            handleCountryChange={setValue}
            selectTheme={"whiteSelect"}
          />
        </Box>
        <Box className="selectSize">
          <SelectSiteLanguage
            showLabel={false}
            handleOnChange={selectLanguage}
            selectTheme={"whiteSelect"}
          />
        </Box>
        <Box className="selectSize">
          <SelectCurrency
            objects={objectsCurrency}
            handleOnChange={selectCurrencyId}
            value={currencyId}
            showLabel={false}
          />
        </Box>
        <Box className="selectSize">
          <MyButton
            className="countryButton"
            variant="outlined"
            cb={onClick}
            borderRadius={0}>
            <Typography className="buttonText">{LL("bt_shop")}</Typography>
          </MyButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export { CountryBlock };

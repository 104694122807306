import { Box } from "@mui/material";
import { Image } from "components/elements/display/Image";

import { BlogDto } from "dto/static/blog.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: BlogDto;
};

const BlogMainImage: React.FC<Props> = ({ object }) => {
  if (!CommonTools.processObjectField(object, ["_galleryDefault", "cdnurl"]))
    return null;
  return (
    <Box className="imageStaticStyle">
      <Box sx={{ maxWidth: "1920px" }}>
        <Image
          url={CommonTools.processObjectField(object, [
            "_galleryDefault",
            "cdnurl",
          ])}
          typeMeasure="percentage"
          withLinK={false}
          // sizeInPx={{
          //   width: 400,
          //   height: 400,
          // }}
          // sizeInPercentage={{
          //   width: "100%",
          //   height: "100%",
          // }}
          sizeInPercentage={{ width: "105%", height: "105%" }}
          style={{ margin: 0, padding: 0, objectFit: "cover" }}
        />
      </Box>
    </Box>
  );
};

export { BlogMainImage };

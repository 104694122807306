import React from "react";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import { Box, Breadcrumbs } from "@mui/material";
import { BreadcrumbItem } from "./BreadcrumbItem";
import { useLabel } from "hooks/useLabel";

type Props = {
  objects: BreadcrumbDto[];
};

const MyBreadcrumbs: React.FC<Props> = ({ objects }) => {
  const { LL } = useLabel();
  const arr = BreadcrumbDto.prepareBreadcrumbForDisplay(objects, LL);
  return (
    <Box className="breadcrumbBox">
      <Breadcrumbs
        separator="/"
        className="breadcrumbs"
        aria-label="breadcrumb"
        color="text.disabled">
        {arr.map((item, index) => (
          <BreadcrumbItem key={index} item={item} index={index} />
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export { MyBreadcrumbs };

import { Box, ListItem, ListItemButton, Typography } from "@mui/material";
import { CategoryProductDto } from "dto/product/categoryproduct.dto";
import { useDrawer } from "hooks/useDrawer";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type Props = {
  item: CategoryProductDto;
};

const DrawerMainItem: React.FC<Props> = ({ item }) => {
  const { selectMainCategory } = useDrawer();

  const onSelect = () => {
    if (!item) return;
    selectMainCategory(item);
  };

  if (!item) return null;
  return (
    <ListItem>
      <ListItemButton
        onClick={onSelect}
        className="listItemButton flexJustifyStart">
        <Typography className="fontSizeGeneral">
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
        <Box>
          <KeyboardArrowRightIcon className="iconSvg" />
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export { DrawerMainItem };

import { Box } from "@mui/material";
import {
  CategoryProductDto,
  GroupedCategory,
} from "dto/product/categoryproduct.dto";
import React from "react";
import { DrawerSecondItem } from "./DrawerSecondItem";

type Props = {
  item: GroupedCategory;
};

const DrawerGroupSecondItem: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  const object = item.object;
  const children = item.children;
  if (!object) return null;
  if (!children) return null;
  return (
    <Box sx={{}}>
      <DrawerSecondItem item={object} />
      <Box sx={{}}>
        {children.map((item: GroupedCategory, index: number) => (
          <DrawerGroupSecondItem item={item} key={index} />
        ))}
      </Box>
    </Box>
  );
};

export { DrawerGroupSecondItem };

import { Box } from "@mui/material";
import { NavigatorDto } from "dto/static/navigator.dto";
import { useConfig } from "hooks/useConfig";
import React, { useEffect, useState } from "react";
import { BottomNavigatorLevel } from "./BottomNavigatorLevel";
import { CommonTools } from "tools/utils/common.tool";
import { MenuItem } from "../general/MenuItem";
import { NavigatorType } from "tools/types/navigatortype";

type Props = {
  item: NavigatorDto;
  level: number;
};

const BottomNavigatorItem: React.FC<Props> = ({ item, level }) => {
  const { CC } = useConfig();
  const maxLevel = parseInt(CC("NAVIGATOR_BOTTOM_MAX_LEVEL", "2"));

  const [nextLevel, setNextLevel] = useState<number>(1);
  useEffect(() => {
    setNextLevel(level + 1);
  }, [level]);

  const processChildren = () => {
    if (nextLevel > maxLevel) return null;
    return (
      <Box>
        <BottomNavigatorLevel
          idParent={CommonTools.processObjectField(item, ["id"])}
          level={nextLevel}
        />
      </Box>
    );
  };
  if (!item) return null;
  const className =
    item.navigatortype === NavigatorType.NO_URL
      ? "standard"
      : "myButtonAnimationWhite";

  const myStyle1 =
    item.navigatortype === NavigatorType.NO_URL
      ? {
          color: "#E2DBA3",
          fontSize: "12px",
          fontWeight: "bold",
          textTransform: "uppercase",
          textDecoration: "none",
          textDecorationColor: "white",
          letterSpacing: "2px",
          mb: 2,
        }
      : {
          color: "#fff",
          fontSize: "12px",
          textDecoration: "none",
          textDecorationColor: "white",
          letterSpacing: "1px",

          mb: 1,
        };

  return (
    <Box>
      <MenuItem myStyle={myStyle1} item={item} className={className} />
      {processChildren()}
    </Box>
  );
};

export { BottomNavigatorItem };

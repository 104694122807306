import React, { useState } from "react";

import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { ProductDto } from "dto/product/product.dto";
import { CommonTools } from "tools/utils/common.tool";
import { RouteTools } from "tools/utils/route.tool";
import { CartButton } from "./CartButton";
import { useProduct } from "hooks/useProduct";
import { ProductOffer } from "./ProductOffer";
import { useLabel } from "hooks/useLabel";
import { MyIcon } from "components/elements/icon/MyIcon";
import { IconType } from "components/elements/icon/IconContext";
import { ButtonWishlist } from "components/product/productwishlist/ButtonWishlist";

type Props = {
  item: ProductDto;
};
const ProductItem: React.FC<Props> = ({ item }) => {
  const { isProductOutOfStock } = useProduct();
  const { LL } = useLabel();
  const imageList = ProductDto.getGalleryCdns(item);
  const [imageUrl, setImageUrl] = useState(
    CommonTools.processImageUrl(
      CommonTools.processObjectField(item, ["_galleryDefault", "cdnurl"]),
      500,
      500
    )
  );

  const navigate = () => {
    const url = CommonTools.processObjectField(item, ["_urlObj", "urlfull"]);
    if (!url) return;

    RouteTools.setHistory(url, {});
  };

  const onMouseEnter = () => {
    if (!imageList.length) return;
    const randomIndex = CommonTools.getRandomNumberInRange(1, 1);
    setImageUrl(imageList[randomIndex]);
  };
  if (!item) return null;

  const isOutOfStock = isProductOutOfStock(item);
  return (
    <Box className="productItem">
      <Card
        className="productItemCard"
        sx={{
          opacity: isOutOfStock ? 0.5 : 1,
        }}>
        <CardActionArea
          onClick={navigate}
          className="cardActionArea"
          onMouseEnter={onMouseEnter}
          onMouseLeave={() =>
            setImageUrl(
              CommonTools.processImageUrl(
                CommonTools.processObjectField(item, [
                  "_galleryDefault",
                  "cdnurl",
                ]),
                500,
                500
              )
            )
          }>
          {imageUrl ? (
            <Box className="cardMediaBox">
              <CardMedia
                className="cardMedia"
                component="img"
                height="13"
                width="13"
                image={imageUrl}
                alt={CommonTools.processObjectField(item, ["_name"])}
              />
            </Box>
          ) : (
            <Box className="noImage">
              <MyIcon
                type={IconType.NO_IMAGE}
                colorSvg="white"
                height="100px"
                width="100px"
              />
            </Box>
          )}
          {isOutOfStock && (
            <Box className="isOutOfStock">
              <Typography color="error" className="outText">
                {LL("outOfStock")}
              </Typography>
            </Box>
          )}

          <CardContent className="cardContent">
            <Typography className="cardContentText">
              {CommonTools.processObjectField(item, ["_name"])}
            </Typography>
            <Typography className="cardContentPrice">
              {CommonTools.processObjectField(item, ["_price", "price"])}
            </Typography>
            <Box>
              <ProductOffer item={item} />
            </Box>
          </CardContent>
        </CardActionArea>
        <CardActions
          className="cardActions"
          sx={{
            position: "absolute",
            zIndex: 20,
            top: { xs: "auto", sm: 4000 },
            bottom: { xs: 120, sm: "auto" },
            right: { xs: 20, sm: 30 },
          }}>
          <Box
            className="cardActionsBox"
            // sx={{ maxWidth: "74px", maxHeight: "74px" }}
          >
            <CartButton object={item} />
          </Box>
          <ButtonWishlist
            idProduct={CommonTools.processObjectField(item, ["id"])}
          />
        </CardActions>
      </Card>
    </Box>
  );
};

export { ProductItem };

import { ProductWishlistRepository } from "repositories/product/productwishlist.repository";
import { GeneralService } from "services/general.service";

export class ProductWishlistService extends GeneralService<ProductWishlistRepository> {
  constructor() {
    super(new ProductWishlistRepository());
  }

  getIsInWishList = async (
    idUser: string,
    idProduct: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.getIsInWishList(
      idUser,
      idProduct,
      this.handleGet,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}

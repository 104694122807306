import React from "react";
import { Box } from "@mui/material";
import { IconType } from "components/elements/icon/IconContext";
import { MyIconButton } from "components/elements/icon/MyIconButton";
import { useDrawer } from "hooks/useDrawer";
import { HeaderBlockProps } from "interfaces/header.interface";


const MenuBlock: React.FC<HeaderBlockProps> = ({ colorSvg }) => {
  const { handleOpenMainDrawer } = useDrawer();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MyIconButton
        onClick={handleOpenMainDrawer}
        typeIcon={IconType.MENU}
        colorSvg={colorSvg}
      />
    </Box>
  );
};

export { MenuBlock };

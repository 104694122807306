import React from "react";

import { AdvertisementDto } from "dto/static/advertisement.dto";
import { Box, Typography } from "@mui/material";

import { CommonTools } from "tools/utils/common.tool";
import { NavButton } from "components/elements/button/NavButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type Props = {
  item: AdvertisementDto;
  index: number;
};

const AdvertisementItemHomeTop: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    <Box className="advertisementItemHomeTop">
      <Box className="mainBanner">
        <Box
          className="bannerAdvertisementItem"
          sx={{
            backgroundImage: `url(${CommonTools.processObjectField(item, [
              "_filecdnurl",
            ])})`,
          }}
        />
      </Box>
      <Box className="bannerAdvertisementItemBox">
        <Box>
          <Typography className="bannerAdvertisementItemText">
            {CommonTools.processObjectField(item, ["_name"])}
          </Typography>
        </Box>
        <Box>
          <Typography className="bannerAdvertisementItemText2">
            {CommonTools.processObjectField(item, ["_shortdescription"])}
          </Typography>
        </Box>
        <Box className="bannerAdvertisementItemButtonBox">
          <Box sx={{}} className="myButtonAnimation2">
            <NavButton
              buttonTheme="textAnimation"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              href={CommonTools.processObjectField(item, ["urltogo"])}>
              {CommonTools.processObjectField(item, ["_btname"])}
              <KeyboardArrowRightIcon
                className=""
                sx={{ mt: "2px", ml: "3px" }}
              />
            </NavButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { AdvertisementItemHomeTop };

import React, { useState } from "react";
import { Box } from "@mui/material";
import { IconType } from "components/elements/icon/IconContext";
import { MyIconButton } from "components/elements/icon/MyIconButton";
import { SearchPopper } from "components/product/search/SearchPopper";
import { HeaderBlockProps } from "interfaces/header.interface";
import { SearchDialog } from "components/product/search/SearchDialog";

const SearchBlock: React.FC<HeaderBlockProps> = ({ colorSvg }) => {
  const [open, setOpen] = useState(false);

  // const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  // const handleClick = (event: any) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);

  const handleClick = () => {
    setOpen(true); // Open the dialog when the button is clicked
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog when handleClose is called
  };

  return (
    <Box>
      <MyIconButton
        onClick={handleClick}
        typeIcon={IconType.SEARCH}
        colorSvg={colorSvg}
        id="basic-button-search"
        aria-controls={open ? "search-popover" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      />
      {/* <SearchPopper anchorEl={anchorEl} open={open} handleClose={handleClose} /> */}
      <SearchDialog open={open} handleClose={handleClose} />
    </Box>
  );
};

export { SearchBlock };

import { RequestCriteriaDTO } from "dto/app/requestcriteria.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CategoryProductDto } from "dto/product/categoryproduct.dto";
import { ProductDto } from "dto/product/product.dto";
import { createDataContext } from "hoc/createDataContext";
import { CategoryProductService } from "services/product/categoryproduct.service";
import { ProductService } from "services/product/product.service";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";
import { RouteTools } from "tools/utils/route.tool";

export type StateResource = {
  categoryProduct: CategoryProductDto | null;
  idCategory: string;
  productList: Array<ProductDto> | null;
  totalProducts: number;
  totalPagesProducts: number;
  pageProducts: number;
  onPageProducts: number;
  filters: RequestFilterDTO[];
  sortCriteria: RequestSortCriteriaDTO;
  breadcrumbCategory: Array<CategoryProductDto> | null;
  codeCategoryProduct: string;
  loadingListProduct: boolean;
  openFilterDrawer: boolean;
  criteriaFilter: RequestCriteriaDTO[];
  rangeFilter: RequestCriteriaDTO[];
};

export type Actions = {
  setCategoryProduct: (categoryProduct: CategoryProductDto | null) => void;
  setIdCategory: (idCategory: string) => void;
  getCategory: (id: string) => void;
  getBreadcrumbCategory: (code: string) => void;
  getProductList: (
    page: number,
    onPage: number,
    filters: RequestFilterDTO[],
    sortCriteria: RequestSortCriteriaDTO | null,
    criteriaFilter: RequestCriteriaDTO[],
    mainUrl: string,
    rangeFilter: RequestCriteriaDTO[]
  ) => void;
  setPageProducts: (page: number) => void;
  setOnPageProducts: (onPage: number) => void;
  setFilters: (filters: RequestFilterDTO[]) => void;
  setSortCriteria: (sortCriteria: RequestSortCriteriaDTO | null) => void;
  setRequestList: (
    page: number,
    onPage: number,
    filters: RequestFilterDTO[],
    sortCriteria: RequestSortCriteriaDTO | null
  ) => void;
  handleOpenFilterDrawer: () => void;
  closeFilterDrawer: () => void;
  resetState: () => void;
  addCriteriaFilter: (id: string, value: string) => void;
  removeCriteriaFilter: (id: string, value: string) => void;
  setCriteriaFilter: (criteria: RequestCriteriaDTO[]) => void;
  replaceCriteriaFilter: (id: string, value: string) => void;
  addRangeFilter: (id: string, values: string[]) => void;
  removeRangeFilterMin: (id: string) => void;
  removeRangeFilterMax: (id: string) => void;
  replaceRangeFilterMin: (id: string, value: string) => void;
  replaceRangeFilterMax: (id: string, value: string) => void;
  setRangeFilter: (rangeFilter: RequestCriteriaDTO[]) => void;
  clearFilters: () => void;
};

const service = new CategoryProductService();
const serviceProduct = new ProductService();

const SET_CATEGORY = "set_main_category";
const SET_ID_CATEGORY = "set_id_category";
const SET_PRODUCT_LIST = "set_product_list";
const SET_BREADCRUMB_CATEGORY = "set_breadcrumb_category";
const SET_LOADING_LIST_PRODUCT = "set_loading_list_product";
const SET_PAGE_PRODUCTS = "set_page_products";
const SET_ON_PAGE_PRODUCTS = "set_on_page_products";
const SET_FILTERS = "set_filters";
const SET_SORT_CRITERIA = "set_sort_criteria";
const SET_REQUEST_LIST = "set_request_list";
const OPEN_FILTER_DRAWER = "open_filter_drawer";
const CLOSE_FILTER_DRAWER = "close_filter_drawer";
const RESET_STATE = "reset_state";
const SET_CRITERIA_FILTER = "set_criteria_filter";
const ADD_CRITERIA_FILTER = "add_criteria_filter";
const REMOVE_CRITERIA_FILTER = "remove_criteria_filter";
const REPLACE_CRITERIA_FILTER = "replace_criteria_filter";
const ADD_RANGE_FILTER = "add_range_filter";
const REMOVE_RANGE_FILTER_MIN = "remove_range_filter_min";
const REMOVE_RANGE_FILTER_MAX = "remove_range_filter_max";
const REPLACE_RANGE_FILTER_MIN = "replace_range_filter_min";
const REPLACE_RANGE_FILTER_MAX = "replace_range_filter_max";
const SET_RANGE_FILTER = "set_range_filter";
const CLEAR_FILTERS = "clear_filters";

const defaultState: StateResource = {
  categoryProduct: null,
  idCategory: "",
  productList: null,
  totalProducts: -1,
  totalPagesProducts: -1,
  pageProducts: 1,
  onPageProducts: Config.ON_PAGE,
  filters: [],
  sortCriteria: RequestSortCriteriaDTO.prepareSortCriteria("order", true),
  breadcrumbCategory: null,
  codeCategoryProduct: "",
  loadingListProduct: false,
  openFilterDrawer: false,
  criteriaFilter: [],
  rangeFilter: [],
};
const resourceReducer = (state: StateResource, action: any) => {
  switch (action.type) {
    case SET_CATEGORY: {
      return {
        ...state,
        categoryProduct: action.payload,
        codeCategoryProduct: CommonTools.processObjectField(action, [
          "payload",
          "hierarchicalcode",
        ]),
      };
    }
    case SET_ID_CATEGORY: {
      return { ...state, idCategory: action.payload, pageProducts: 1 };
    }
    case SET_PRODUCT_LIST: {
      return {
        ...state,
        productList: action.payload.objects,
        totalProducts: action.payload.total,
        totalPagesProducts: action.payload.totalpages,
        loadingListProduct: false,
      };
    }
    case SET_BREADCRUMB_CATEGORY: {
      return { ...state, breadcrumbCategory: action.payload };
    }
    case SET_LOADING_LIST_PRODUCT: {
      return { ...state, loadingListProduct: true };
    }
    case SET_PAGE_PRODUCTS: {
      return { ...state, pageProducts: action.payload };
    }
    case SET_ON_PAGE_PRODUCTS: {
      return { ...state, onPageProducts: action.payload };
    }
    case SET_FILTERS: {
      return { ...state, filters: action.payload };
    }
    case SET_SORT_CRITERIA: {
      return { ...state, sortCriteria: action.payload };
    }
    case SET_REQUEST_LIST: {
      return {
        ...state,
        pageProducts: action.payload.page,
        onPageProducts: action.payload.onPage,
        filters: action.payload.filters,
        sortCriteria: action.payload.sortCriteria,
      };
    }
    case OPEN_FILTER_DRAWER: {
      return { ...state, openFilterDrawer: true };
    }
    case CLOSE_FILTER_DRAWER: {
      return { ...state, openFilterDrawer: false };
    }
    case SET_CRITERIA_FILTER: {
      return { ...state, criteriaFilter: action.payload };
    }
    case ADD_CRITERIA_FILTER: {
      const id = action.payload.id;
      const value = action.payload.value;
      const filters = [...state.criteriaFilter];
      const filter = state.criteriaFilter.find((c) => c.id === id);
      if (filter) {
        const values = filter.values || [];
        if (!values.includes(value)) {
          filter.values = [...values, value];
        }
      } else {
        const filter = new RequestCriteriaDTO(id, [value]);
        filters.push(filter);
      }
      return {
        ...state,
        criteriaFilter: filters,
        pageProducts: 1,
      };
    }
    case REPLACE_CRITERIA_FILTER: {
      const id = action.payload.id;
      const value = action.payload.value;
      const filters = [...state.criteriaFilter];
      const filter = state.criteriaFilter.find((c) => c.id === id);
      if (filter) {
        filter.values = [value];
      } else {
        const filter = new RequestCriteriaDTO(id, [value]);
        filters.push(filter);
      }
      return {
        ...state,
        criteriaFilter: filters,
        pageProducts: 1,
      };
    }
    case REMOVE_CRITERIA_FILTER: {
      const id = action.payload.id;
      const value = action.payload.value;
      const filters = [...state.criteriaFilter];
      const filter = state.criteriaFilter.find((c) => c.id === id);
      if (filter) {
        filter.values = filter.values.filter((v) => v !== value) || [];
        if (filter.values.length === 0) {
          const index = filters.indexOf(filter);
          if (index > -1) filters.splice(index, 1);
        }
      }
      return {
        ...state,
        criteriaFilter: filters,
        pageProducts: 1,
      };
    }
    case ADD_RANGE_FILTER: {
      const id = action.payload.id;
      const values = action.payload.values;
      const filters = [...state.rangeFilter];
      const filter = state.rangeFilter.find((c) => c.id === id);
      if (filter) {
        filter.values = values;
      } else {
        const filter = new RequestCriteriaDTO(id, values);
        filters.push(filter);
      }
      return {
        ...state,
        rangeFilter: filters,
        pageProducts: 1,
      };
    }
    case REMOVE_RANGE_FILTER_MIN: {
      const id = action.payload.id;
      const filters = [...state.rangeFilter];
      const filter = state.rangeFilter.find((c) => c.id === id);
      if (filter) {
        const values = filter.values || [];
        if (values.length === 2) filter.values = ["0", values[1]];
        else filter.values = ["0", "0"];
        if (values.length === 2 && values[0] === "0" && values[1] === "0") {
          const index = filters.indexOf(filter);
          if (index > -1) filters.splice(index, 1);
        }
      }
      return {
        ...state,
        rangeFilter: filters,
        pageProducts: 1,
      };
    }
    case REMOVE_RANGE_FILTER_MAX: {
      const id = action.payload.id;
      const filters = [...state.rangeFilter];
      const filter = state.rangeFilter.find((c) => c.id === id);
      if (filter) {
        const values = filter.values || [];
        if (values.length === 2) filter.values = [values[0], "0"];
        else filter.values = ["0", "0"];
        if (values.length === 2 && values[0] === "0" && values[1] === "0") {
          const index = filters.indexOf(filter);
          if (index > -1) filters.splice(index, 1);
        }
      }
      return {
        ...state,
        rangeFilter: filters,
        pageProducts: 1,
      };
    }
    case REPLACE_RANGE_FILTER_MIN: {
      const id = action.payload.id;
      const value = action.payload.value;
      const filters = [...state.rangeFilter];
      const filter = state.rangeFilter.find((c) => c.id === id);
      if (filter) {
        const values = filter.values || [];
        if (values.length === 2) filter.values = [value, values[1]];
        else filter.values = [value, "0"];
      } else {
        const filter = new RequestCriteriaDTO(id, [value, "0"]);
        filters.push(filter);
      }
      return {
        ...state,
        rangeFilter: filters,
        pageProducts: 1,
      };
    }
    case REPLACE_RANGE_FILTER_MAX: {
      const id = action.payload.id;
      const value = action.payload.value;
      const filters = [...state.rangeFilter];
      const filter = state.rangeFilter.find((c) => c.id === id);
      if (filter) {
        const values = filter.values || [];
        if (values.length === 2) filter.values = [values[0], value];
        else filter.values = ["0", value];
      } else {
        const filter = new RequestCriteriaDTO(id, ["0", value]);
        filters.push(filter);
      }

      return {
        ...state,
        rangeFilter: filters,
        pageProducts: 1,
      };
    }
    case SET_RANGE_FILTER: {
      return { ...state, rangeFilter: action.payload };
    }
    case CLEAR_FILTERS: {
      return { ...state, criteriaFilter: [], rangeFilter: [], pageProducts: 1 };
    }
    case RESET_STATE: {
      return defaultState;
    }

    default:
      return state;
  }
};

const clearFilters = (dispatch: any) => () => {
  dispatch({ type: CLEAR_FILTERS });
};

const setRangeFilter =
  (dispatch: any) => (rangeFilter: RequestCriteriaDTO[]) => {
    dispatch({ type: SET_RANGE_FILTER, payload: rangeFilter });
  };

const addRangeFilter = (dispatch: any) => (id: string, values: string[]) => {
  if (!id || !values) return;
  dispatch({ type: ADD_RANGE_FILTER, payload: { id, values } });
};

const removeRangeFilterMin = (dispatch: any) => (id: string) => {
  if (!id) return;
  dispatch({ type: REMOVE_RANGE_FILTER_MIN, payload: { id } });
};

const removeRangeFilterMax = (dispatch: any) => (id: string) => {
  if (!id) return;
  dispatch({ type: REMOVE_RANGE_FILTER_MAX, payload: { id } });
};

const replaceRangeFilterMin =
  (dispatch: any) => (id: string, value: string) => {
    if (!id || !value) return;
    dispatch({ type: REPLACE_RANGE_FILTER_MIN, payload: { id, value } });
  };

const replaceRangeFilterMax =
  (dispatch: any) => (id: string, value: string) => {
    if (!id || !value) return;
    dispatch({ type: REPLACE_RANGE_FILTER_MAX, payload: { id, value } });
  };

const replaceCriteriaFilter =
  (dispatch: any) => (id: string, value: string) => {
    if (!id || !value) return;
    dispatch({ type: REPLACE_CRITERIA_FILTER, payload: { id, value } });
  };
const removeCriteriaFilter = (dispatch: any) => (id: string, value: string) => {
  if (!id || !value) return;
  dispatch({ type: REMOVE_CRITERIA_FILTER, payload: { id, value } });
};
const addCriteriaFilter = (dispatch: any) => (id: string, value: string) => {
  if (!id || !value) return;
  dispatch({ type: ADD_CRITERIA_FILTER, payload: { id, value } });
};

const setCriteriaFilter =
  (dispatch: any) => (criteria: RequestCriteriaDTO[]) => {
    dispatch({ type: SET_CRITERIA_FILTER, payload: criteria });
  };
const resetState = (dispatch: any) => () => {
  dispatch({ type: RESET_STATE });
};

const setCategoryProduct =
  (dispatch: any) => (categoryProduct: CategoryProductDto | null) => {
    dispatch({ type: SET_CATEGORY, payload: categoryProduct });
  };

const setIdCategory = (dispatch: any) => (idCategory: string) => {
  dispatch({ type: SET_ID_CATEGORY, payload: idCategory });
};

const getCategory = (dispatch: any) => (id: string) => {
  service.get(id, handleGet, { dispatch });
};

const handleGet =
  (dispatch: any) => (result: ResultObjectDTO<CategoryProductDto>) => {
    if (!result) return;
    if (result.error) return;
    setCategoryProduct(dispatch)(result.obj ?? null);
  };

const getBreadcrumbCategory = (dispatch: any) => (code: string) => {
  const req = new RequestListDTO();
  req.page = 1;
  req.onpage = -1;
  req.filters = [
    RequestFilterDTO.prepareFilter("hpcodes", [code]),
    RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
  ];
  req.sortcriteria = [
    RequestSortCriteriaDTO.prepareSortCriteria("level", true),
  ];
  service.getList(handleGetBreadcrumbCategory, { dispatch }, req);
};

const handleGetBreadcrumbCategory =
  (dispatch: any) => (result: ResultListDTO<CategoryProductDto>) => {
    if (!result) return;
    if (result.error) return;
    dispatch({
      type: SET_BREADCRUMB_CATEGORY,
      payload: result.objects ?? null,
    });
  };

const getProductList =
  (dispatch: any) =>
  (
    page: number,
    onPage: number,
    filters: RequestFilterDTO[],
    sortCriteria: RequestSortCriteriaDTO,
    criteriaFilter: RequestCriteriaDTO[],
    mainUrl: string,
    rangeFilter: RequestCriteriaDTO[]
  ) => {
    const req = new RequestListDTO();
    req.page = page;
    req.onpage = parseInt(
      Config.CC("on_page_category_product", Config.ON_PAGE.toString())
    )
      ? parseInt(
          Config.CC("on_page_category_product", Config.ON_PAGE.toString())
        )
      : onPage;
    req.filters = filters;
    req.sortcriteria = [sortCriteria];
    req.criteria = criteriaFilter;
    req.range = rangeFilter;
    dispatch({ type: SET_LOADING_LIST_PRODUCT });
    const urlPart = RouteTools.prepareListLocation(req);
    RouteTools.setHistory(mainUrl + urlPart, {});

    serviceProduct.getList(handleGetProductList, { dispatch }, req);
  };

const handleGetProductList =
  (dispatch: any) => (result: ResultListDTO<ProductDto>) => {
    if (!result) return;
    if (result.error) return;
    const objects = result.objects || [];
    const total = result.total || 0;
    const totalpages = result.totalpages || 0;
    dispatch({
      type: SET_PRODUCT_LIST,
      payload: {
        objects,
        total,
        totalpages,
      },
    });
  };

const setPageProducts = (dispatch: any) => (page: number) => {
  dispatch({ type: SET_PAGE_PRODUCTS, payload: page });
};

const setOnPageProducts = (dispatch: any) => (onPage: number) => {
  dispatch({ type: SET_ON_PAGE_PRODUCTS, payload: onPage });
};

const setFilters = (dispatch: any) => (filters: RequestFilterDTO[]) => {
  dispatch({ type: SET_FILTERS, payload: filters });
};

const setSortCriteria =
  (dispatch: any) => (sortCriteria: RequestSortCriteriaDTO | null) => {
    dispatch({ type: SET_SORT_CRITERIA, payload: sortCriteria });
  };

const setRequestList =
  (dispatch: any) =>
  (
    page: number,
    onPage: number,
    filters: RequestFilterDTO[],
    sortCriteria: RequestSortCriteriaDTO | null
  ) => {
    dispatch({
      type: SET_REQUEST_LIST,
      payload: { page, onPage, filters, sortCriteria },
    });
  };

const handleOpenFilterDrawer = (dispatch: any) => () => {
  dispatch({ type: OPEN_FILTER_DRAWER });
};

const closeFilterDrawer = (dispatch: any) => () => {
  dispatch({ type: CLOSE_FILTER_DRAWER });
};

export const { Provider, Context } = createDataContext<StateResource, Actions>(
  resourceReducer,
  {
    setCategoryProduct,
    setIdCategory,
    getCategory,
    getBreadcrumbCategory,
    getProductList,
    setPageProducts,
    setOnPageProducts,
    setFilters,
    setSortCriteria,
    setRequestList,
    handleOpenFilterDrawer,
    closeFilterDrawer,
    resetState,
    addCriteriaFilter,
    removeCriteriaFilter,
    setCriteriaFilter,
    replaceCriteriaFilter,
    addRangeFilter,
    removeRangeFilterMin,
    removeRangeFilterMax,
    replaceRangeFilterMin,
    replaceRangeFilterMax,
    setRangeFilter,
    clearFilters,
  },
  defaultState
);

import React, { useEffect } from "react";
import { IPage } from "interfaces/page.interface";
import { Box, Divider } from "@mui/material";
import { useCategoryProduct } from "hooks/useCategoryProduct";
import { CommonTools } from "tools/utils/common.tool";
import { CategoryTopBlock } from "components/product/categoryproduct/general/CategoryTopBlock";
import { CategoryBreadcrumbs } from "components/product/categoryproduct/general/CategoryBreadcrumbs";
import { ProductList } from "components/product/product/list/ProductList";
import { ProductPagination } from "components/product/product/list/ProductPagination";
import { Filters } from "components/product/product/list/Filters";
import { SorterBlock } from "components/elements/sorter/SorterBlock";
import { useConfig } from "hooks/useConfig";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { useResponsive } from "hooks/useResponsive";

const CategoryProductPage: React.FC<IPage> = ({ currentRoute }) => {
  const mainUrl = CommonTools.processObjectField(currentRoute, ["url"]).split(
    "?"
  )[0];
  const { CC } = useConfig();
  const sortIdentifiers = CC(
    "category_product_sort_identifiers",
    "order,price"
  );

  const { downSM } = useResponsive();

  const {
    getCategoryProduct,
    setIdCategory,
    getBreadcrumb,
    getDefaultFilters,
    getProducts,
    resetState,
    setSortCriteria,
    sortCriteria,
  } = useCategoryProduct();
  const id = CommonTools.processIdFromPaths(currentRoute, 2)
    ? CommonTools.processIdFromPaths(currentRoute, 2)
    : CommonTools.processObjectField(currentRoute, ["processedData", "idobj"]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    if (!id) return;
    setIdCategory(id);
  }, [id]);

  useEffect(() => {
    getCategoryProduct();
  }, [getCategoryProduct]);

  useEffect(() => {
    getBreadcrumb();
  }, [getBreadcrumb]);

  useEffect(() => {
    getDefaultFilters(currentRoute);
  }, [getDefaultFilters]);

  useEffect(() => {
    getProducts(
      mainUrl,
      CommonTools.processObjectField(currentRoute, ["_mainobject"])
    );
  }, [getProducts, mainUrl]);

  if (!currentRoute) return null;
  if (!id) return null;
  return (
    <Box>
      <Box className="productTopBlock">
        <CategoryBreadcrumbs />
        <Box
          className={downSM ? "flexJustifyCenter" : "flexJustifyEnd"}
          sx={{ gap: 2 }}>
          <Box>
            <Filters />
          </Box>
          <Divider
            className="verticalDivider"
            orientation="vertical"
            flexItem
          />
          <Box>
            <SorterBlock
              setSortCriteria={setSortCriteria}
              sortCriteria={sortCriteria}
              fields={RequestSortCriteriaDTO.prepareIdentifiers(
                sortIdentifiers
              )}
            />
          </Box>
        </Box>
      </Box>
      <CategoryTopBlock />
      <ProductList />
      <ProductPagination />
    </Box>
  );
};

export { CategoryProductPage };

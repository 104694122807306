import { IconButton, Skeleton } from "@mui/material";
import { useProductWishlist } from "hooks/useProductWishlist";
import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useUser } from "hooks/useUser";
type Props = {
  idProduct: string;
};

const ButtonWishlist: React.FC<Props> = ({ idProduct }) => {
  const { loading, inWishlist, addInWishlist, removeFromWishlist, object } =
    useProductWishlist(idProduct);
  const { user } = useUser();
  const icon = inWishlist ? <FavoriteIcon /> : <FavoriteBorderIcon />;
  const handleOnClick = () => {
    if (inWishlist) {
      removeFromWishlist();
    } else {
      addInWishlist();
    }
  };

  if (!user) return null;
  // if (loading) return <Skeleton />;
  if (!object) return null;
  return <IconButton onClick={handleOnClick}>{icon}</IconButton>;
};

export { ButtonWishlist };

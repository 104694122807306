import Idto from "interfaces/idto.interface";

export class SystemInfoDto implements Idto {
  langcode: string;
  currencycode: string;
  contryid: string;
  constructor(langcode: string, currencycode: string, contryid: string) {
    this.langcode = langcode;
    this.currencycode = currencycode;
    this.contryid = contryid;
  }
}

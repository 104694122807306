import { Box, Grid } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { CategoryProductDto } from "dto/product/categoryproduct.dto";

import { useList } from "hooks/useList";
import React from "react";
import { CategoryProductService } from "services/product/categoryproduct.service";
import { Status } from "tools/types/status";
import { CategoryProductItemHome } from "./CategoryProductItemHome";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { SkeletonCategoryProductList } from "components/elements/skeleton/product/SkeletonCategoryProductList";

const service = new CategoryProductService();
const CategoryProductListHome: React.FC = () => {
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  const [loading, objects] = useList<CategoryProductDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("showonhome", ["1"]),
      ],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    ),
    [],
    true,
    "categoryproductlisthome"
  );

  const processItem = (item: CategoryProductDto, index: number) => {
    if (!item) return null;
    let md = 6;
    if (index === 0) md = 12;
    return (
      <Grid md={md} key={index} item sx={{ height: "100vh", width: "100%" }}>
        <CategoryProductItemHome item={item} index={index} />
      </Grid>
    );
  };
  if (loading)
    return (
      <Box sx={{ mt: 3 }}>
        <SkeletonCategoryProductList />
      </Box>
    );
  if (!objects) return null;
  if (!objects.length) return null;
  return (
    <Grid container className="categoryProductListHome">
      {objects.map((item: CategoryProductDto, index: number) =>
        processItem(item, index)
      )}
    </Grid>
  );
};

export { CategoryProductListHome };

import { GeneralSelect } from "components/elements/select/GeneralSelect";
import { CurrencyDto } from "dto/static/currency.dto";
import { useCountry } from "hooks/useCountry";
import React from "react";

type Props = {
  showLabel?: boolean;
  handleOnChange?: (code: string) => void;
  objects?: CurrencyDto[];
  value?: string;
  selectTheme?: any;
};
const SelectCurrency: React.FC<Props> = ({
  showLabel = true,
  handleOnChange,
  objects,
  value,
  selectTheme
}) => {
  const { currencyId, changeCurrency, currencyObjects } = useCountry();

  if (!handleOnChange) handleOnChange = changeCurrency;
  if (!objects) objects = currencyObjects;
  if (!value) value = currencyId;
  if (!selectTheme) selectTheme = "whiteSelect";

  return (
    <GeneralSelect
      options={CurrencyDto.parseToSelectOptions(objects)}
      value={value}
      setValue={handleOnChange}
      _label="currency"
      showLabel={showLabel}
      selectTheme={selectTheme}
    />
  );
};

export { SelectCurrency };

import { Box, Typography } from "@mui/material";
import { HtmlTag } from "components/elements/display/HtmlTag";
import { Logo } from "components/general/Logo";
import { usePage } from "hooks/usePage";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";
import { LinkToAboutUs } from "./LinkToAboutUs";
import Divider from "@mui/material/Divider";
import { SkeletonAboutUs } from "components/elements/skeleton/SkeletonAboutUs";
import { useTheme } from "@mui/material/styles";

const AboutUsHome: React.FC = () => {
  const id = Config.ID_ABOUT_US_ON_HOME_PAGE_BLOCK;
  const theme = useTheme();

  const { object, loading } = usePage(id, true, "about_us_block_home");

  if (!id) return null;
  if (loading)
    return (
      <Box sx={{ mt: 3 }}>
        <SkeletonAboutUs />
      </Box>
    );
  if (!object) return null;
  return (
    <Box className="mainBanner " sx={{}}>
      <Box
        className="aboutUsHome"
        sx={{
          backgroundImage: `linear-gradient(rgba(23, 14, 14, 0.85), rgba(23, 14, 14, 0.85)), url(${CommonTools.processObjectField(
            object,
            ["_galleryDefault", "cdnurl"]
          )})`,
        }}>
        <Box className="flexJustifyCenter">
          <Box className="categoryProductItemHomeBox">
            <Box data-aos="zoom-in" className="categoryProductItemHomeLogo">
              {/* <Logo width={429} height={257} /> */}
              <Logo width="100%" height="100%" withLink={false} />
            </Box>
            <Divider
              data-aos="zoom-in"
              className="categoryProductItemHomeDivider"
            />
            <Typography
              data-aos="fade-up"
              data-aos-delay="50"
              className="categoryProductItemHomeText">
              {CommonTools.processObjectField(object, ["_name"])}
            </Typography>
            <Box
              data-aos="fade-up"
              data-aos-delay="150"
              className="categoryProductItemHomeDescription">
              <HtmlTag
                content={CommonTools.processObjectField(object, [
                  "_description",
                ])}
              />
            </Box>
            <Box data-aos="zoom-in" sx={{ mt: 3 }}>
              <LinkToAboutUs />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { AboutUsHome };

import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import { BlogDto } from "dto/static/blog.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { logger } from "tools/utils/logger";
import { RouteTools } from "tools/utils/route.tool";
import { useLabel } from "hooks/useLabel";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type Props = {
  item: BlogDto;
};

const BlogItem: React.FC<Props> = ({ item }) => {
  const { LL } = useLabel();

  const navigate = () => {
    const url = CommonTools.processObjectField(item, ["_urlObj", "urlfull"]);

    if (!url) return;
    RouteTools.setHistory(url, {});
  };
  if (!item) return null;
  return (
    <Card
      className="blogItemCard"
      sx={
        {
          // height: index && index % 3 === 0 ? "80vh" : "70vh"
        }
      }>
      <CardActionArea onClick={navigate} className="infoCardBlogItem">
        <CardMedia
          className="blogItemCardMedia"
          component="img"
          // height="345"
          image={CommonTools.processImageUrl(
            CommonTools.processObjectField(item, ["_galleryDefault", "cdnurl"]),
            1000,
            1000
          )}
          alt={CommonTools.processObjectField(item, ["_name"])}
        />
        <Box className="infoBoxCardBlogItem">
          <Typography className="blogItemCardDiscoverMore">
            {LL("discoverMoreBlog")}
            <KeyboardArrowRightIcon className="" sx={{ ml: "3px" }} />
          </Typography>
          <Box className="textBoxMovingCart">
            <Typography className="blogIItemCardTitle">
              {CommonTools.processObjectField(item, ["_name"])}
            </Typography>
            <Typography className="blogIItemCardSubTitle">
              {CommonTools.processObjectField(item, ["_shortdescription"])}
            </Typography>
          </Box>
        </Box>

        {/* <CardContent>
          <Typography className="blogItemCardRefCode">
            {CommonTools.processObjectField(item, ["refcode"])}
          </Typography>
          <Typography className="blogIItemCardTitle">
            {CommonTools.processObjectField(item, ["_name"])}
          </Typography>
          <Typography className="blogIItemCardSubTitle">
            {CommonTools.processObjectField(item, ["_shortdescription"])}
          </Typography>
        </CardContent> */}
      </CardActionArea>
    </Card>
  );
};

export { BlogItem };

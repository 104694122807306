import React from "react";
import { Box } from "@mui/material";
import { useLabel } from "hooks/useLabel";
import { NavButton } from "components/elements/button/NavButton";

type Props = {
  code: string;
  label: string;
};

const StaticHTTPCodePage: React.FC<Props> = ({ code, label }) => {
  const { LL } = useLabel();

  return (
    <Box className="dialogPages">
      <Box className="errorCodeText">{code}</Box>
      <Box className="errorText">{LL(label)}</Box>
      <NavButton
        sx={{ fontSize: "16px", mt: 1 }}
        href={"/"}
        underline="hover"
        color="text.secondary">
        {LL("404_backHome")}
      </NavButton>
    </Box>
  );
};

export { StaticHTTPCodePage };

import { Menu } from "@mui/material";
import React from "react";
import { RouteTools } from "tools/utils/route.tool";
import { UserDto, UserMenuType } from "dto/user/user.dto";
import { useUser } from "hooks/useUser";
import { UserTopMenuItem } from "./UserTopMenuItem";
type Props = {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
};

const UserTopMenu: React.FC<Props> = ({ anchorEl, open, handleClose }) => {
  const { logout, isAuth } = useUser();
  const handleNavigate = (url?: string,anchor?:string) => {
    if (!url) return;
    RouteTools.setHistory(url, {},anchor);
    handleClose();
  };
  const handleLogout = (url?: string) => {
    if (!url) return;
    logout();
    RouteTools.setHistory(url, {});
    handleClose();
  };
  const objects: UserMenuType[] = UserDto.processUserMenu([
    ...UserDto.getActionBasicAction(handleNavigate),
    UserDto.addAction("logout", handleLogout),
  ],isAuth);
 
  return (
    <Menu
      id="basic-menu-user_top_menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      variant="menu"
      MenuListProps={{
        "aria-labelledby": "basic-button-user_top_menu",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      className="user_top_menu-tmp"
    >
      {objects.map((item, index) => (
        <UserTopMenuItem key={index} item={item} />
      ))}
    </Menu>
  );
};

export { UserTopMenu };

import { Box, Grid } from "@mui/material";
import { BlogList } from "components/static/blog/list/BlogList";
import { BlogPagination } from "components/static/blog/list/BlogPagination";
import { BlogCategoryList } from "components/static/blogcategory/BlogCategoryList";
import { useBlogCategory } from "hooks/useBlogCategory";

import { IPage } from "interfaces/page.interface";
import React, { useEffect } from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";

const BlogCategoryPage: React.FC<IPage> = ({ currentRoute }) => {
  let id = CommonTools.processIdFromPaths(currentRoute, 2)
    ? CommonTools.processIdFromPaths(currentRoute, 2)
    : CommonTools.processObjectField(currentRoute, ["processedData", "idobj"])
    ? CommonTools.processObjectField(currentRoute, ["processedData", "idobj"])
    : "";

  const { getCategoryList, getListBlog, getDefaultFilters, setIdBlogCategory } =
    useBlogCategory();

  useEffect(() => {
    getDefaultFilters(currentRoute);
  }, [getDefaultFilters]);

  useEffect(() => {
    setIdBlogCategory(id);
  }, [id]);

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList]);

  useEffect(() => {
    if (!currentRoute) return;
    if (!currentRoute._mainobject) return;
    if (
      CommonTools.processObjectField(currentRoute, ["_mainobject"]) !==
      "categoryblog"
    ) {
      return;
    }
    getListBlog();
  }, [getListBlog]);

  return (
    <Box className="blogCategoryStyle">
      <Box className="blogCategoryBox">
        <Box className="blogList">
          <BlogList />
        </Box>
        {/* <Box className="blogCategoryListBox">
          <BlogCategoryList />
        </Box> */}
      </Box>
      <Box className="paginationStyle">
        <BlogPagination />
      </Box>
    </Box>
  );
};

export { BlogCategoryPage };

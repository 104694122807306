import React from "react";
import { SvgIconProps, SvgIcon } from "@mui/material";
import { IconContext } from "./IconContext";

type Props = SvgIconProps & {
  type: string;
  colorSvg?: string;
  colorFill?: string;
  width?: string;
  height?: string;
};

const MyIcon: React.FC<Props> = ({
  type,
  colorSvg,
  colorFill,
  width,
  height,
  ...props
}) => {
  return (
    <SvgIcon sx={{ width: width, height: height }} {...props} inheritViewBox>
      <IconContext
        type={type}
        colorSvg={colorSvg}
        colorFill={colorFill}
        width={width}
        height={height}
      />
    </SvgIcon>
  );
};

export { MyIcon };

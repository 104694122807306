import { Box, ListItem, ListItemButton, Typography } from "@mui/material";
import { CategoryProductDto } from "dto/product/categoryproduct.dto";
import { useDrawer } from "hooks/useDrawer";
import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { RouteTools } from "tools/utils/route.tool";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  item: CategoryProductDto;
};

const DrawerSecondItem: React.FC<Props> = ({ item }) => {
  const { closeAllDrawers } = useDrawer();

  const handleNavigate = () => {
    if (!CommonTools.processObjectField(item, ["_urlObj", "urlfull"])) return;
    RouteTools.setHistory(
      CommonTools.processObjectField(item, ["_urlObj", "urlfull"]),
      {}
    );
    closeAllDrawers();
  };

  if (!item) return null;
  return (
    <ListItem>
      <ListItemButton
        onClick={handleNavigate}
        className="listItemButton flexJustifyStart">
        <Typography className="fontSizeGeneral">
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
        <Box>
          <KeyboardArrowRightIcon className="iconSvg" />
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export { DrawerSecondItem };

import Idto from "interfaces/idto.interface";
import { UserDto } from "dto/user/user.dto";
import { ProductDto } from "./product.dto";

export class ProductWishlistDto implements Idto {
  id?: number | string;
  idproduct?: number;
  iduser?: number;
  date?: number;
  userObj?: UserDto;
  productObj?: ProductDto;

  constructor(
    id?: number | string,
    idproduct?: number,
    iduser?: number,
    date?: number
  ) {
    this.id = id || 0;
    this.idproduct = idproduct || 0;
    this.iduser = iduser || 0;
    this.date = date || 0;
  }
}

export class IsInWishlistDto implements Idto {
  id: number;
  inwishlist: number;

  constructor(id?: number, inwishlist?: number) {
    this.inwishlist = inwishlist || 0;
    this.id = id || 0;
  }
}

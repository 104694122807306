import React from "react";
import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonMenuFilterOptions: React.FC<SkeletonProps> = ({ lines }) => {
  const gridItem = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignContent: "center",
        // marginX: "auto",
        flexDirection: "column",
        width: "100%",
        mb: 3,
      }}
    >
      <Skeleton variant="text" width="100px" sx={{ height: "18px" }} />
      <Box sx={{ ml: 4 }}>
        <Skeleton variant="text" width="130px" sx={{ mt: 3, height: "18px" }} />
        <Skeleton variant="text" width="130px" sx={{ mt: 3, height: "18px" }} />
        <Skeleton variant="text" width="130px" sx={{ mt: 3, height: "18px" }} />
        <Skeleton variant="text" width="130px" sx={{ mt: 3, height: "18px" }} />
      </Box>
    </Box>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          //   margin: "auto",
          padding: "20px 0px 20px 0px",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", width: "100%" }}
          spacing={2}
        >
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonMenuFilterOptionsRow: React.FC<SkeletonProps> = ({ lines }) => {
  const gridItem = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignContent: "center",
        // marginX: "auto",
        flexDirection: "column",
        width: "100%",
        mb: 3,
      }}
    >
      <Skeleton variant="text" width="130px" sx={{ height: "18px" }} />
    </Box>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          //   margin: "auto",
          padding: "20px 0px 20px 0px",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", width: "100%" }}
          spacing={2}
        >
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonMenuFilterOptions, SkeletonMenuFilterOptionsRow };

import { IPRepository } from "repositories/system/ip.repository";
import { GeneralService } from "../general.service";

export class IPService extends GeneralService<IPRepository> {
  constructor() {
    super(new IPRepository());
  }

  get = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.get(this.handleGet, cbparameters);

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}

import { Box, Grid } from "@mui/material";
import { GalleryDto } from "dto/system/gallery.dto";
import React from "react";
import { GalleryItem } from "./GalleryItem";

type Props = {
  objects?: Array<GalleryDto>;
  excludeDefault?: boolean;
};

const GalleryList: React.FC<Props> = ({ objects, excludeDefault = false }) => {
  const processItem = (item: GalleryDto, index: number) => {
    if (!item || !objects) return null;
    let gridTemplateColumns;
    const totalImages = objects.length;

    if (totalImages === 1) {
      gridTemplateColumns = 12;
    } else if (totalImages === 2) {
      gridTemplateColumns = 6;
    } else if (totalImages === 3) {
      gridTemplateColumns = index === 0 ? 12 : 6;
    } else if (totalImages === 4) {
      gridTemplateColumns = index === 1 || index === 2 ? 6 : 12;
    } else if (totalImages === 5) {
      gridTemplateColumns = index === 2 ? 12 : 6;
    } else if (totalImages >= 6) {
      gridTemplateColumns =
        (index === 0 || index % 3 === 0) && objects?.length % 2 !== 0 ? 12 : 6;
    }

    return (
      <Grid
        item
        xs={gridTemplateColumns}
        sx={{ height: { xs: "30vh", sm: "50vh", md: "70vh" }, width: "100%" }}
        key={index}>
        <Box
          data-aos="fade-up"
          data-aos-delay={`${index * 50}`}
          sx={{ height: "100%", width: "100%" }}>
          <GalleryItem item={item} />
        </Box>
      </Grid>
    );
  };
  if (!objects) return null;
  if (!objects.length) return null;
  if (excludeDefault) {
    objects = objects.filter((item) => item.isdefault === 0);
  }

  if (!objects.length) return null;

  return (
    <Grid
      container
      sx={{
        overflow: "hidden",
        position: "relative",
        "::after": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          border: "20px solid white",
          boxSizing: "border-box",
          pointerEvents: "none",
        },
      }}>
      {objects.map((item, index) => processItem(item, index))}
    </Grid>
  );
};

export { GalleryList };

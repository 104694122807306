import GeneralRepository from "repositories/general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class ProductWishlistRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("productwishlist");
  }

  getIsInWishList = async (
    idUser: string,
    idProduct: string,
    cb?: any,
    cbParams?: any
  ) => {
    const url = `/${this.mainUrl}/isinwishlist/${idUser}/${idProduct}`;
    return await this.getObject(url, cb, cbParams);
  };
}

import React from "react";
import { IPage } from "interfaces/page.interface";

import { Box, Divider, Typography } from "@mui/material";
import { useLabel } from "hooks/useLabel";
import { PaymentBlock } from "components/sale/paymentblock/PaymentBlock";
import { CartBlock } from "components/sale/cartblock/CartBlock";
import { CheckoutImage } from "components/sale/paymentblock/CheckoutImage";
import { MyBreadcrumbs } from "components/elements/breadcrumb/MyBreadcrumbs";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import { Config } from "tools/utils/config";
import { useResponsive } from "hooks/useResponsive";

const CheckoutPage: React.FC<IPage> = () => {
  const { LL } = useLabel();
  const { matchesCustom } = useResponsive();

  return (
    <Box className="staticStylePage">
      <Box className="breadcrumbBoxCheckoutPage" />
      <Box className="photoBoxStatic">
        <Box>
          <CheckoutImage />
          <Box className="photoBoxStaticMask" />
        </Box>
      </Box>
      <Box className="productTopBlock">
        <MyBreadcrumbs objects={[new BreadcrumbDto(LL("checkout"))]} />
      </Box>
      <Box className="textBoxRelativeStatic">
        <Typography className="textStaticStyleRelative">
          {LL("checkout")}
        </Typography>
      </Box>

      <Box
        className="textSectionStatic"
        sx={{
          flexDirection: matchesCustom ? "row" : "column-reverse",
        }}>
        <Box sx={{ height: "100%", width: matchesCustom ? "50%" : "100%" }}>
          <Typography className="paymentTitlesText">
            {LL("payemntInfo")}
          </Typography>
          <Box className="dividerBox">
            <Divider className="divider" />
          </Box>
          <PaymentBlock />
        </Box>
        <Box sx={{ height: "100%", width: matchesCustom ? "50%" : "100%" }}>
          <Typography className="paymentTitlesText">
            {LL("cartItems")}
          </Typography>
          <Box className="dividerBox">
            <Divider className="divider" />
          </Box>
          <CartBlock />
        </Box>
      </Box>
    </Box>
  );
};

export { CheckoutPage };

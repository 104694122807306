import { Context, Actions, StateResource } from "contexts/drawer.context";
import { CategoryProductDto } from "dto/product/categoryproduct.dto";
import { useMemo, useContext, useCallback } from "react";
import { useResource } from "./useResource";

type Props = () => {
  getListMain: () => void;
  handleOpenMainDrawer: () => void;
  handleOpenSecondaryDrawer: () => void;
  selectMainCategory: (category: CategoryProductDto) => void;
  closeMainDrawer: () => void;
  closeSecondaryDrawer: () => void;
  closeAllDrawers: () => void;
  getListSecondary: () => void;
} & StateResource;

export const useDrawer: Props = () => {
  const { state, actions } = useContext(Context);
  const { saveCache, getCache } = useResource();
  const {
    openMainDrawer,
    openSecondaryDrawer,
    secondaryCategoryObjects,
    selectedMainCategory,
    totalMainCategoryObjects,
    totalSecondaryCategoryObjects,
    codeMainCategory,
    mainCategoryObjects,
  } = state;
  const {
    handleOpenMainDrawer,
    handleOpenSecondaryDrawer,
    closeMainDrawer,
    closeSecondaryDrawer,
    closeAllDrawers,
    getListMainCategory,
    selectMainCategory,
    getListSecondaryCategory,
  } = actions as Actions;

  const getListMain = useCallback(() => {
    getListMainCategory(saveCache, getCache);
  }, []);

  const getListSecondary = useCallback(() => {
    if (!codeMainCategory) return;
    getListSecondaryCategory(codeMainCategory, saveCache, getCache);
  }, [codeMainCategory]);

  return useMemo(
    () => ({
      openMainDrawer,
      openSecondaryDrawer,
      secondaryCategoryObjects,
      selectedMainCategory,
      totalMainCategoryObjects,
      totalSecondaryCategoryObjects,
      codeMainCategory,
      mainCategoryObjects,
      getListMain,
      handleOpenMainDrawer,
      handleOpenSecondaryDrawer,
      closeMainDrawer,
      closeSecondaryDrawer,
      closeAllDrawers,
      selectMainCategory,
      getListSecondary,
    }),
    [
      openMainDrawer,
      openSecondaryDrawer,
      mainCategoryObjects,
      secondaryCategoryObjects,
      selectedMainCategory,
      totalMainCategoryObjects,
      totalSecondaryCategoryObjects,
      codeMainCategory,
      getListMain,
      getListSecondary,
    ]
  );
};

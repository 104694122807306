import React, { useEffect, useLayoutEffect, useState } from "react";
import { CookiesProvider } from "react-cookie";
import theme from "assets/themes/theme";

import { ThemeProvider } from "@mui/material/styles";
import { ResourceProvider } from "providers/ResourceProvider";
import { MessageProvider } from "providers/MessageProvider";
import { LabelProvider } from "providers/LabelProvider";
import { RoutesProvider } from "providers/RoutesProvider";
import { HeadProvider } from "providers/HeadProvider";
import { JWTProvider } from "providers/JWTProvider";
import { LanguageProvider } from "providers/LanguageProvider";
import { UserProvider } from "providers/UserProvider";
import { ConfigProvider } from "providers/ConfigProvider";
import GeneralAxiosRepository from "repositories/generalaxios.repository";
import { UserAgentProvider } from "react-ua";
import { BotProvider } from "providers/BotProvider";
import { CountryProvider } from "providers/CountryProvider";
import { CookieUsageProvider } from "providers/CookieUsageProvider";
import { ServerError } from "components/general/ServerError";
import { Provider as DrawerProvider } from "contexts/drawer.context";
import { Provider as CategoryProductProvider } from "contexts/categoryproduct.context";
import { Provider as ProductProvider } from "contexts/product.context";
import { Provider as CartProvider } from "contexts/cart.context";
import { Provider as SearchProvider } from "contexts/search.context";
import { LocalStorageTools } from "api/localstorage.api";
import { RouteTools } from "tools/utils/route.tool";
import { ConfigFileProvider } from "providers/ConfigFileProvider";

import AOS from "aos";

const App: React.FC = () => {
  const [_theme, setTheme] = useState({});
  const [serverError, setServerError] = useState<boolean>(false);
  useEffect(() => {
    setTheme(theme);
  }, []);

  const handleReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useLayoutEffect(() => {
    GeneralAxiosRepository.setReloadFunction(handleReload);
    GeneralAxiosRepository.setServerError(processServerError);
    GeneralAxiosRepository.setHandleUnauthorized(handleUnauthorized);
    GeneralAxiosRepository.setForbiddenFunction(handleForbidden);
  }, []);

  const handleUnauthorized = () => {
    handleReload();
    LocalStorageTools.saveValue("token", "");
    LocalStorageTools.saveValue("remember_token", "");
    RouteTools.setHistory("/login", {});
  };

  const handleForbidden = () => {
    handleReload();
    RouteTools.setHistory("/forbidden", {});
  };

  const processServerError = () => {
    setServerError(true);
  };

  if (serverError)
    return (
      <ThemeProvider theme={_theme}>
        <ServerError />
      </ThemeProvider>
    );

  return (
    <UserAgentProvider>
      <BotProvider>
        <ThemeProvider theme={_theme}>
          <JWTProvider>
            <CookiesProvider
              defaultSetOptions={{
                sameSite: "strict",
              }}>
              <HeadProvider>
                <LanguageProvider>
                  <UserProvider>
                    <ResourceProvider>
                      <LabelProvider>
                        <MessageProvider>
                          <ConfigProvider>
                            <ConfigFileProvider>
                              <CountryProvider>
                                <CookieUsageProvider>
                                  <DrawerProvider>
                                    <CategoryProductProvider>
                                      <ProductProvider>
                                        <CartProvider>
                                          <SearchProvider>
                                            <RoutesProvider />
                                          </SearchProvider>
                                        </CartProvider>
                                      </ProductProvider>
                                    </CategoryProductProvider>
                                  </DrawerProvider>
                                </CookieUsageProvider>
                              </CountryProvider>
                            </ConfigFileProvider>
                          </ConfigProvider>
                        </MessageProvider>
                      </LabelProvider>
                    </ResourceProvider>
                  </UserProvider>
                </LanguageProvider>
              </HeadProvider>
            </CookiesProvider>
          </JWTProvider>
        </ThemeProvider>
      </BotProvider>
    </UserAgentProvider>
  );
};

export default App;

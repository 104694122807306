import React, { useEffect, useState } from "react";
import { MessageTypes } from "tools/types/messagetypes";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Config } from "tools/utils/config";
import MessageDto from "dto/app/message.dto";
type Props = {
  _obj: MessageDto;
  _closeMessage: (obj: any) => void;
};
type Severity = "success" | "warning" | "error";
const MessageItem: React.FC<Props> = ({ _obj, _closeMessage, ...props }) => {
  const [typeClass, setTypeClass] = useState<Severity>("success");

  const clMess = () => {
    if (!_closeMessage) return;
    _closeMessage(_obj);
  };

  const processClassType = () => {
    let type: Severity = "success";

    if (_obj && _obj.type === MessageTypes.MESSAGE_SUCCESS) type = "success";
    if (_obj && _obj.type === MessageTypes.MESSAGE_WARNING) type = "warning";
    if (_obj && _obj.type === MessageTypes.MESSAGE_ERROR) type = "error";

    setTypeClass(type);
  };

  const processAutomatedClosed = () => {
    if (!_obj) return;

    let exp = 10000;

    if (_obj.type === MessageTypes.MESSAGE_SUCCESS)
      exp = Config.CLOSE_MESSAGE_SUCCESS;
    if (_obj.type === MessageTypes.MESSAGE_WARNING)
      exp = Config.CLOSE_MESSAGE_WARNING;
    if (_obj.type === MessageTypes.MESSAGE_ERROR)
      exp = Config.CLOSE_MESSAGE_ERROR;
    if (isNaN(exp)) exp = 10000;

    const timer = setTimeout(() => {
      clMess();
    }, exp);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    processClassType();
    processAutomatedClosed();
  }, [_obj]);

  return _obj ? (
    <Alert
      className="alertStyle"
      severity={typeClass}
      onClick={clMess}
      onClose={() => {}}>
      <AlertTitle>{_obj.code}</AlertTitle>
      {_obj.message}
    </Alert>
  ) : null;
};

export { MessageItem };

import React from "react";

import { BlogDto } from "dto/static/blog.dto";
import { useList } from "hooks/useList";
import { BlogService } from "services/static/blog.service";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Box, Typography } from "@mui/material";
import { BlogItemHome } from "./BlogItemHome";
import { Carousel } from "components/elements/carousel/MyCarousel";
import { useLabel } from "hooks/useLabel";

const service = new BlogService();
const BlogListHome: React.FC = () => {
  const { LL } = useLabel();
  const getList = (cb?: any, cbParams?: any, reqList?: RequestListDTO) => {
    service.getList(cb, cbParams, reqList);
  };
  const [loading, objects] = useList<BlogDto>(
    getList,
    new RequestListDTO([
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
      RequestFilterDTO.prepareFilter("showonhome", ["1"]),
    ]),
    [],
    true,
    "blog_list_home"
  );

  const processItem = (item: BlogDto, index: number) => {
    if (!item) return null;
    return <BlogItemHome key={index} item={item} />;
  };
  if (loading) return null;
  if (!objects) return null;
  if (!objects.length) return null;
  return (
    <Box className="blogListHome">
      <Typography className="blogListHomeText">{LL("article_blog")}</Typography>
      <Box className="blogListHomeBox">
        <Carousel _slidesToShow={1}>
          {objects.map((item: BlogDto, index: number) =>
            processItem(item, index)
          )}
        </Carousel>
      </Box>
    </Box>
  );
};

export { BlogListHome };

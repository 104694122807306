import { Box } from "@mui/material";
import { AttachmentList } from "components/elements/attachment/AttachmentList";
import { PageBreadcrumb } from "components/static/page/content/PageBreadcrumb";
import { PageContent } from "components/static/page/content/PageContent";
import { PageMainImage } from "components/static/page/content/PageMainImage";
import { PageShortDescription } from "components/static/page/content/PageShortDescription";
import { PageTitle } from "components/static/page/content/PageTitle";
import { PageVideo } from "components/static/page/content/PageVideo";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { PageDto } from "dto/static/page.dto";
import { IPage } from "interfaces/page.interface";
import React, { useCallback, useEffect, useState } from "react";
import { PageService } from "services/static/page.service";
import { CommonTools } from "tools/utils/common.tool";
import { GalleryList } from "components/elements/gallery/GalleryList";
import { Config } from "tools/utils/config";
import { SkeletonBreadCrumb } from "components/elements/skeleton/SkeletonBreadCrumbs";
import { SkeletonStaticPage } from "components/elements/skeleton/blog/SkeletonStaticPage";

const service = new PageService();
const StaticPage: React.FC<IPage> = ({ currentRoute }) => {
  const [object, setObject] = useState<PageDto | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [idObject, setIdObject] = useState<string>("");

  const getObjectInfo = useCallback(() => {
    let id = CommonTools.processObjectField(currentRoute, [
      "processedData",
      "idobj",
    ]);
    if (!id) {
      id = CommonTools.processObjectField(currentRoute, [
        "processedData",
        "_obj",
        "id",
      ]);
    }
    if (!id) {
      id = CommonTools.processObjectField(currentRoute, [
        "processedData",
        "_getVars",
        "id",
      ]);
    }
    const object =
      currentRoute &&
      currentRoute.processedData &&
      currentRoute.processedData._obj
        ? currentRoute.processedData._obj
        : null;

    setIdObject(id);
    setObject(object);
  }, [currentRoute]);

  const getObject = useCallback(() => {
    if (!idObject) return;
    if (object && object.id && object.id === parseInt(idObject)) return;
    setLoading(true);
    service.get(idObject, handleGet, {});
  }, [idObject, object]);

  const handleGet = (result: ResultObjectDTO<PageDto>) => {
    if (!result) return;
    if (result.error) return;
    const object = result.obj ? result.obj : null;
    setObject(object);
  };
  const checkLoading = useCallback(() => {
    let loading = false;
    if (!object) loading = true;
    setLoading(loading);
  }, [object]);

  useEffect(() => {
    getObjectInfo();
  }, [getObjectInfo]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  useEffect(() => {
    getObject();
  }, [getObject]);

  if (loading)
    return (
      <Box sx={{ width: "100%" }}>
        <SkeletonBreadCrumb />
        <SkeletonStaticPage />
      </Box>
    );
  if (!object) return null;
  return (
    <Box className="staticStylePage">
      <Box className="breadcrumbBoxCheckoutPage" />
      <Box className="photoBoxStatic">
        <Box sx={{ width: "100%" }}>
          <PageMainImage object={object} />
          <Box className="photoBoxStaticMask" />
        </Box>
      </Box>
      <Box className="productTopBlock">
        <PageBreadcrumb object={object} />
      </Box>
      <Box
        data-aos="zoom-in"
        data-aos-delay="150"
        className="textBoxRelativeStatic">
        <PageTitle object={object} textColor="white" />
      </Box>
      <Box className="textSectionStaticBlog">
        <Box sx={{ width: "100%" }} data-aos="fade-up" data-aos-delay="150">
          <PageShortDescription object={object} />
        </Box>
        <Box>
          <GalleryList objects={object._galleryActiveList} excludeDefault />
        </Box>
        <Box className="blogContentStyle">
          <Box className="textShortDescription">
            <Box data-aos="fade-up" data-aos-delay="150">
              <PageContent object={object} />
            </Box>
            <Box>
              <PageVideo object={object} />
            </Box>
            <AttachmentList objects={object._attachmentActiveList} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { StaticPage };

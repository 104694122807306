import React from "react";
import { IPage } from "interfaces/page.interface";
import { SignUpForm } from "components/authentication/SignUpForm";
import { Box, Container } from "@mui/material";
import { useUser } from "hooks/useUser";
import { Config } from "tools/utils/config";
import { useSeoInfo } from "hooks/useSeoInfo";

const SignUpPage: React.FC<IPage> = () => {
  const { signUp } = useUser();
  useSeoInfo("signup");
  return (
    <Box className="authMaineBox">
      <Container maxWidth="sm" className="authContainer">
        <Box className="authBox">
          <SignUpForm onSubmit={signUp} />
        </Box>
      </Container>
    </Box>
  );
};

export { SignUpPage };

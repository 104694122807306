import { Box } from "@mui/material";
import { CountrySelect } from "components/static/country/CountrySelect";
import { SelectCurrency } from "components/static/currency/SelectCurrency";
import { SelectSiteLanguage } from "components/static/language/SelectSiteLanguage";
import { useCountry } from "hooks/useCountry";
import React from "react";

const RegionBlock: React.FC = () => {
  const { countryObjects, country, changeCountry } = useCountry();
  return (
    <Box>
      <SelectSiteLanguage selectTheme={"transparentSelect"} />
      <Box sx={{ mt: 6 }}>
        <CountrySelect
          objects={countryObjects}
          selectedCountryCode={country}
          handleCountryChange={changeCountry}
          selectTheme={"transparentSelect"}
        />
      </Box>
      <Box mt={6}>
        <SelectCurrency showLabel selectTheme={"transparentSelect"} />
      </Box>
    </Box>
  );
};

export { RegionBlock };

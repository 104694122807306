import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { logger } from "tools/utils/logger";

type CustomColor =
  | "blackButtonStyle"
  | "whiteButtonStyle"
  | "blackTransparentButtonStyle"
  | "blackTransparent2ButtonStyle";

export type MyButtonProps = ButtonProps & {
  children: React.ReactNode;
  cb?:
    | ((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | (() => void);
  type?: "button" | "submit" | "reset";
  borderRadius?: string | number;
  width?: string | number;
  customColor?: CustomColor;
};

const MyButton: React.FC<MyButtonProps> = ({
  children,
  cb,
  type = "button",
  borderRadius = "0px",
  width = "100%",
  customColor,
  sx,
  ...props
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (type !== "submit") {
      e.stopPropagation();
      e.preventDefault();
    }

    if (cb !== undefined) {
      if (cb.length === 1) {
        cb(e);
      } else {
        cb();
      }
    }
  };

  // const customStyles = {
  //   ...(customColor === "blackButtonStyle" && {
  //     backgroundColor: "#000",
  //     color: "#fff",
  //     "&:hover": {
  //       backgroundColor: "#333",
  //       color: "#fff",
  //     },
  //   }),
  //   ...(customColor === "whiteButtonStyle" && {
  //     backgroundColor: "#fff",
  //     color: "#000",
  //     "&:hover": {
  //       backgroundColor: "#000",
  //       color: "#fff",
  //     },
  //   }),
  //   ...(customColor === "blackTransparentButtonStyle" && {
  //     backgroundColor: "#FFF",
  //     color: "#000",
  //     border: "1px solid #000",
  //     borderRadius: "100px",
  //     boxShadow: "0px 0px 0px 0px #000",
  //     px: 3,

  //     "&:hover": {
  //       backgroundColor: "#000",
  //       color: "#fff",
  //     },
  //   }),
  //   ...(customColor === "blackTransparent2ButtonStyle" && {
  //     backgroundColor: "#FFF",
  //     color: "#000",
  //     border: "1px solid #000",
  //     borderRadius: "100px",
  //     boxShadow: "0px 0px 0px 0px #000",
  //     px: 3,
  //     "& svg": {
  //       fill: "#000 !important",
  //       // stroke: "#000 !important",
  //     },
  //     "&:hover": {
  //       backgroundColor: "#000",
  //       color: "#fff",
  //       "& svg": {
  //         fill: "#fff !important",
  //         // stroke: "#fff !important",
  //       },
  //     },
  //   }),
  // };

  return (
    <Button
      // className={customColor}
      type={type}
      color="primary"
      variant="contained"
      onClick={handleClick}
      sx={{
        borderRadius: borderRadius,
        width: width,
        // ...customStyles,
        ...sx,
      }}
      {...props}>
      {children}
    </Button>
  );
};

export { MyButton };

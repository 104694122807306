import React from "react";
import { IPage } from "interfaces/page.interface";
import { Box, Container } from "@mui/material";
import { useUser } from "hooks/useUser";
import { LoginForm } from "components/authentication/LoginForm";

import { Config } from "tools/utils/config";
import { AdvertisementLoginPage } from "components/static/advertisement/AdvertisementLoginPage";
import { useSeoInfo } from "hooks/useSeoInfo";

const LoginPage: React.FC<IPage> = () => {
  const { login } = useUser();
  useSeoInfo("login");
  return (
    <Box className="authMaineBox" sx={{}}>
      <Container maxWidth="sm" className="authContainer">
        <Box className="authBox">
          <LoginForm onSubmit={login} />
        </Box>
      </Container>

      <AdvertisementLoginPage />
    </Box>
  );
};

export { LoginPage };

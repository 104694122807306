import { Box, Typography } from "@mui/material";
import { OrderDto } from "dto/sale/order.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: OrderDto;
};

const PaymentIndividual: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  if (!object) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_fct_fiz_name")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_fct_fiz_name"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_fct_fiz_idno")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_fct_fiz_idno"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_fct_fiz_docnumber")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_fct_fiz_docnumber"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_fct_fiz_address")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_fct_fiz_address"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_fct_fiz_phone")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_fct_fiz_phone"])}
        </Typography>
      </Box>
    </Box>
  );
};

export { PaymentIndividual };

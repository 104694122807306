import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { inherits } from "util";
import { useResponsive } from "hooks/useResponsive";
import { SkeletonCartItem } from "./product/SkeletonCartItem";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonOrderInfo: React.FC<SkeletonProps> = ({ lines }) => {
  const { matchesXS, matchesSM, matchesMD, matchesLG, matchesCustom } =
    useResponsive();

  const gridLine = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      {" "}
      <Skeleton
        variant="text"
        sx={{
          width: { xs: "120px", md: "180px" },
          height: { xs: "24px", md: "32px" },
        }}
      />
      <Skeleton
        variant="text"
        sx={{
          width: { xs: "120px", md: "180px" },
          height: { xs: "24px", md: "32px" },
        }}
      />
    </Box>
  );

  const gridLineLong = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      {" "}
      <Skeleton
        variant="text"
        sx={{
          width: { xs: "150px", md: "210px" },
          height: { xs: "24px", md: "32px" },
        }}
      />
      <Skeleton
        variant="text"
        sx={{
          width: { xs: "100px", md: "160px" },
          height: { xs: "24px", md: "32px" },
        }}
      />
    </Box>
  );

  const gridLineShort = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      {" "}
      <Skeleton
        variant="text"
        sx={{
          width: { xs: "100px", md: "190px" },
          height: { xs: "24px", md: "32px" },
        }}
      />
      <Skeleton
        variant="text"
        sx={{
          width: { xs: "140px", md: "190px" },
          height: { xs: "24px", md: "32px" },
        }}
      />
    </Box>
  );

  const gridItem = (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        flexDirection: "column",
        gap: 4,
        mb: 5,
      }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: { xs: "column", md: "row" },
            gap: 4,
          }}>
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}>
            {Array(lines)
              .fill(null)
              .map((_, index) => (
                <>
                  <React.Fragment>{gridLine}</React.Fragment>
                  <React.Fragment>{gridLineLong}</React.Fragment>
                  <React.Fragment>{gridLineShort}</React.Fragment>
                </>
              ))}
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}>
            {Array(lines)
              .fill(null)
              .map((_, index) => (
                <>
                  <React.Fragment>{gridLineShort}</React.Fragment>
                  <React.Fragment>{gridLine}</React.Fragment>
                  <React.Fragment>{gridLineLong}</React.Fragment>
                </>
              ))}
          </Box>
        </Box>
      </Box>
      <Grid container sx={{ width: "100%" }} spacing={2}>
        <SkeletonCartItem lines={3} />
      </Grid>
    </Box>
  );
  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 20,
          //   maxHeight: "620px",

          //   margin: "auto",
          //   padding: "20px 0px 20px 0px",
        }}>
        <Skeleton
          variant="text"
          sx={{
            width: { xs: "200px", md: "300px" },
            height: { xs: "24px", md: "32px" },
          }}
        />
        <Grid
          container
          sx={{
            // maxWidth: "1522px",
            width: "100%",
            overflow: "hidden",
            mt: 4,
            px: { xs: 4, sm: 6, md: 10 },
          }}
          spacing={2}>
          {Array(1)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonOrderInfo };

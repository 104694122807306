import React from "react";

import { AdvertisementDto } from "dto/static/advertisement.dto";
import { Box, Container, Skeleton, Typography } from "@mui/material";
import { useAdvertisementList } from "hooks/useAdvertisementList";
import { AdvertisementSection } from "tools/types/advertisementsection";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { AdvertisementItem } from "./AdvertisementItem";
import { useLabel } from "hooks/useLabel";
import { NavButton } from "components/elements/button/NavButton";
import { SkeletonLoginPage } from "components/elements/skeleton/SkeletonLoginPage";

const AdvertisementLoginPage: React.FC = () => {
  const { objects, loading } = useAdvertisementList(
    AdvertisementSection.LOGIN_PAGE_BENEFITS,
    true,
    "login_page_advertisement_list"
  );
  const { LL } = useLabel();
  const processItem = (item: AdvertisementDto, index: number) => {
    if (!item) return null;
    return <AdvertisementItem key={index} index={index} item={item} />;
  };

  if (loading) return <SkeletonLoginPage />;
  if (!objects) return null;
  if (!objects.length) return null;

  return (
    <Container maxWidth="sm" className="advertisementLoginContainer">
      <Box className="advertisementLoginBox">
        <Box className="advertisementLoginBoxStyle">
          <Typography className="advertisementLoginBenefitsTitle">
            {LL("create_account_title")}
          </Typography>
          <Typography className="advertisementLoginBenefitsText" mt={4}>
            {LL("create_account_benefits")}
          </Typography>
          {objects.map((item: AdvertisementDto, index: number) =>
            processItem(item, index)
          )}
        </Box>
        <Box className="flexJustifyEnd">
          <Box className="listItemButton advertisementLoginButton">
            <NavButton
              href={"/signup"}
              buttonTheme="blackButton"
              className="advertisementLoginNavButton">
              <Typography className="advertisementLoginNavButtonText">
                {LL("registerNow")}
              </Typography>
              <Box className="arrowSvg flexJustifyCenter ">
                <KeyboardArrowRightIcon
                  className="iconSvg2"
                  // sx={{ display: "none" }}
                />
              </Box>
            </NavButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export { AdvertisementLoginPage };

import { SystemRepository } from "../../repositories/system/system.repository";
import { GeneralService } from "../general.service";

export class SystemService extends GeneralService<SystemRepository> {
  constructor() {
    super(new SystemRepository());
  }

  changeInfo = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.changeInfo(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getBySlug = async (
    slug: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    if (slug.includes("?")) slug = slug.split("?")[0];
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getBySlug(
      slug,
      this.handleGet,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getSeoInfo = async (
    suffix?: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getSeoInfo(
      suffix,
      this.handleGet,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}

import React, { useLayoutEffect, useState } from "react";
import { IPage } from "interfaces/page.interface";
import { Box } from "@mui/material";

import RequestListDTO from "dto/app/requestlist.dto";
import { useUser } from "hooks/useUser";
import { useList } from "hooks/useList";
import { ProductWishlistDto } from "dto/product/productwishlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { CommonTools } from "tools/utils/common.tool";
import { useConfig } from "hooks/useConfig";
import { Config } from "tools/utils/config";

import { MyPagination } from "components/elements/pagination/MyPagination";
import { RouteTools } from "tools/utils/route.tool";
import { useCookies } from "react-cookie";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { UserWishlistList } from "components/product/productwishlist/ProductWishlistList";
import { ProductWishlistService } from "services/product/productwishlist.service";
import { SkeletonProductWishList } from "components/elements/skeleton/product/SkeletonProductWishList";

const service = new ProductWishlistService();
const UserWishlistPage: React.FC<IPage> = ({ currentRoute }) => {
  const mainUrl = CommonTools.processObjectField(currentRoute, ["url"]).split(
    "?"
  )[0];

  const { CC } = useConfig();
  const { user } = useUser();
  const [cookies] = useCookies();
  const id = user ? CommonTools.processObjectField(user, ["id"]) : "";
  const getList = (cb?: any, cbParam?: any, req?: RequestListDTO) => {
    if (!id) return;
    const urlPart = RouteTools.prepareListLocation(req ?? {});
    RouteTools.setHistory(mainUrl + urlPart, {}, "userwishlist");
    service.getList(cb, cbParam, req);
  };
  const onPage = parseInt(CC("on_page_wishlist", Config.ON_PAGE.toString()));
  const [page, setPage] = useState(1);
  const [loading, objects, total, totalPages] = useList<ProductWishlistDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("iduser", [id])],
      page,
      onPage,
      [RequestSortCriteriaDTO.prepareSortCriteria("date", false)]
    ),
    [id, page]
  );

  useLayoutEffect(() => {
    const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
    if (reqListRoute.page) setPage(reqListRoute.page);
  }, []);

  if (!objects) return null;
  return (
    <Box>
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <SkeletonProductWishList lines={4} />
        </Box>
      ) : (
        <UserWishlistList objects={objects} />
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          py: 2,
        }}>
        <MyPagination
          page={page}
          setPage={setPage}
          totalPage={totalPages}
          total={total}
        />
      </Box>
    </Box>
  );
};

export { UserWishlistPage };

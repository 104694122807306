import React from "react";

import { PayMethodDTO } from "dto/static/paymethod.dto";
import { Box, Link } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { Image } from "components/elements/display/Image";

type Props = {
  item: PayMethodDTO;
};

const PayMethodItem: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  if (!CommonTools.processObjectField(item, ["_galleryDefault", "cdnurl"]))
    return null;
  return (
    <Box>
      <Link
        href={CommonTools.processObjectField(item, ["url"])}
        target="_blank"
        rel="noreferrer"
      >
        <Image
          url={CommonTools.processObjectField(item, [
            "_galleryDefault",
            "cdnurl",
          ])}
          typeMeasure="pixel"
          sizeInPx={{ width: 40, height: 30 }}
          withLinK={false}
        />
      </Link>
    </Box>
  );
};
export { PayMethodItem };

import { Box, Typography } from "@mui/material";
import { SocialPageList } from "components/static/socialpage/SocialPageList";
import { useLabel } from "hooks/useLabel";
import React from "react";

const SocialBlock: React.FC = () => {
  const { LL } = useLabel();
  return (
    <Box sx={{ mb: 3 }}>
      <Typography className="bottomMeuLabelsText">
        {LL("follow_us_on_social_media")}
      </Typography>
      <SocialPageList />
    </Box>
  );
};

export { SocialBlock };

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import IProvider from "interfaces/provider.interface";
import { Loading } from "components/elements/loading/Loading";
import {UserService} from "services/user/user.service";
import ResultObjectDTO from "dto/app/resultobject.dto";

import { jwtDecode } from "jwt-decode";

import {
  AuthDto,
  ChangePasswordDto,
  LoginDto,
  LoginSiteDto,
  SignupDto,
  UserDto,
} from "dto/user/user.dto";
import { CallbackType } from "interfaces/commontypes.interface";
import { RouteTools } from "tools/utils/route.tool";
import { CustomJWTPayload, JWTDto } from "dto/app/jwt.dto";
import { JWTContext } from "./JWTProvider";
import { CommonTools, isTokenExpired } from "tools/utils/common.tool";

import { LocalStorageTools } from "api/localstorage.api";

type Props = {
  signUp: (data: SignupDto, cb?: CallbackType) => void;
  user: UserDto | null;
  logout: () => void;
  login: (data: LoginSiteDto, cb?: CallbackType) => void;
  changeProfile: (data: UserDto, cb?: CallbackType) => void;
  changePassword : (data: ChangePasswordDto, cb?: CallbackType) => void;
  handleLogin: (result: ResultObjectDTO<AuthDto>, cbParam?: any) => void;
};

var isAuth = false;
export const UserContext = createContext<Props>({
  signUp: (data: SignupDto, cb?: CallbackType) => {},
  user: null,
  logout: () => {},
  login: (data: LoginSiteDto, cb?: CallbackType) => {},
  changeProfile: (data: UserDto, cb?: CallbackType) => {},
changePassword: (data: ChangePasswordDto, cb?: CallbackType) => {},
handleLogin: (result: ResultObjectDTO<AuthDto>, cbParam?: any) => {},
});

const service = new UserService();

type StateResource = {
  user: UserDto | null;
  loading: boolean;
  rememberToken: string | null;

  nextLocation: NextLocation | null;
};



const reducer = (state: StateResource, action: any) => {
  switch (action.type) {
    case "process_user": {
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case "set_loading": {
      return {
        ...state,
        loading: action.payload.loading,
      };
    }
    case "logout": {
      LocalStorageTools.saveValue("remember_token", "");
      return {
        ...state,
        user: null,
        rememberToken: null,
        nextLocation: { pathname: "/", search: "" },
      };
    }
    case "remember_token": {
      return {
        ...state,
        rememberToken: action.payload.rememberToken,
      };
    }
    case "set_next_location": {
      return {
        ...state,
        nextLocation: action.payload.nextLocation,
      };
    }
    

    default:
      return state;
  }
};

type NextLocation = {
  pathname: string;
  search: string;
};
export const UserProvider: React.FC<IProvider> = ({ children }) => {
  const { token, processToken } = useContext(JWTContext);
  const [state, dispatch] = useReducer(reducer, {
    user: processUserFromToken(token),
    loading: true,
    rememberToken: processRememberTokenLocal(),
    nextLocation: null,
  });
  const { user, loading, rememberToken, nextLocation } = state;
  // const [nextLocation, setNextLocation] = useState<NextLocation | null>(null);
  const setNextLocation = (nextLocation: NextLocation | null) => {
    dispatch({ type: "set_next_location", payload: { nextLocation } });
  };
  const setLoading = (loading: boolean) => {
    dispatch({ type: "set_loading", payload: { loading } });
  };

  const signUp = async (data: SignupDto, cb?: CallbackType) => {
    if (cb) service.signup(handleSignUp, { cb }, data);
    else service.signup(handleSignUp, {}, data);
  };

  const changeProfile = (data: UserDto, cb?: CallbackType) => {
    service.changeProfile(handleChangeProfile, { cb }, data);
  };

  const changePassword = (data: ChangePasswordDto, cb?: CallbackType) => {
    service.changePassword(handleChangeProfile, { cb }, data);
  }

  const handleChangeProfile = (
    result: ResultObjectDTO<AuthDto>,
    cbParam?: any
  ) => {
    if (!result) {
      logout();
      if (cbParam) CommonTools.checkForCallback(cbParam);
      return;
    }
    if (result.error) {
      logout();
      if (cbParam) CommonTools.checkForCallback(cbParam);
      return;
    }
    if (!result.obj) {
      logout();
      if (cbParam) CommonTools.checkForCallback(cbParam);
      return;
    }

    const remember = rememberToken ? true : false;
    processResultAuthDto(result.obj, remember);
    if (cbParam) CommonTools.checkForCallback(cbParam);
  };
  const handleSignUp = (result: ResultObjectDTO<AuthDto>, cbParam?: any) => {
    if (!result) {
      CommonTools.checkForCallback(cbParam);
      return;
    }
    if (result.error) {
      CommonTools.checkForCallback(cbParam);
      return;
    }
    if (!result.obj) return;
    processResultAuthDto(result.obj);
    CommonTools.checkForCallback(cbParam);
    RouteTools.setHistory("/", {})
  };

  const processResultAuthDto = (obj: AuthDto, remember?: boolean) => {
    if (!remember) remember = false;
    if (!obj) return;
    if (obj.hasOwnProperty("user") && obj.user) processUser(obj.user);
    if (obj.hasOwnProperty("token") && obj.token) processNewToken(obj.token);
    if (remember && obj.hasOwnProperty("remember") && obj.remember)
      processRememberToken(obj.remember);
  };

  const processUser = (obj: UserDto) => {
    if (!obj) return;
    dispatch({ type: "process_user", payload: { user: obj } });
  };

  const processNewToken = (token: JWTDto) => {
    // logger("processNewToken", token);
    processToken(CommonTools.processObjectField(token, ["token"]));
  };

  const checkUser = useCallback(() => {
    if (user) return true;
    else return false;
  }, [user]);

  const checkHasAccess = useCallback(() => {
    return true;
  }, []);

  useEffect(() => {
    RouteTools.setHasAccess(checkHasAccess);
    RouteTools.setCheckUser(checkUser);
  }, [checkHasAccess, checkUser]);

  
  const logout = () => {
    service.logout(handleLogout, {});
  };

  const handleLogout = (result: ResultObjectDTO<JWTDto>) => {
    if (!result) return;
    if (result.error) return;
    if (!result.obj) return;
    processNewToken(result.obj);
    RouteTools.setHistory("/", {})
    dispatch({ type: "logout" });
    
  };

  const login = (data: LoginSiteDto, cb?: CallbackType) => {
    const remember = data.remember;
    service.login(
      handleLogin,
      { cb, remember },
      LoginDto.parseFromLoginSiteDto(data)
    );
  };

  const handleLogin = (result: ResultObjectDTO<AuthDto>, cbParam?: any) => {
    if (!result) {
      CommonTools.checkForCallback(cbParam);
      return;
    }
    if (CommonTools.processNumberToBoolean(result.error)) {
      // logger("handleLogin", result.error);
      CommonTools.checkForCallback(cbParam);
      return;
    }
    if (!result.obj) return;
    let remember = false;
    if (cbParam && cbParam.hasOwnProperty("remember") && cbParam.remember)
      remember = cbParam.remember;
    processResultAuthDto(result.obj, remember);
    CommonTools.checkForCallback(cbParam);
    isAuth = false;
    RouteTools.setHistory("/", {})
  };

  const processRememberToken = (token: string) => {
    LocalStorageTools.saveValue("remember_token", token);
    dispatch({ type: "remember_token", payload: { rememberToken: token } });
  };

  const signByRememberToken = useCallback(() => {
    if (isAuth) return;

    if (!rememberToken) return;
    if (user) return;
    if (isTokenExpired(rememberToken)) {
      dispatch({ type: "logout" });
      return;
    }
    setLoading(true);
    isAuth = true;
    service.getUserByToken(rememberToken, handleLogin, { remember: true });
  }, [rememberToken, user]);

  useEffect(() => {
    signByRememberToken();
  }, [signByRememberToken]);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (isAuth) loading = true;
    setLoading(loading);
  }, [isAuth]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const value = {
    signUp,
    user,
    logout,
    login,
    changeProfile,
    changePassword,
    handleLogin
  };

  return loading ? (
    <Loading />
  ) : (
    <UserContext.Provider value={value}>{children}</UserContext.Provider>
  );
};

const processUserFromToken = (token: string | null): UserDto | null => {
  if (!token) return null;
  const obj = jwtDecode<CustomJWTPayload>(token);
  if (!obj) return null;
  let user: UserDto | null = null;
  user = UserDto.parseFromTokenPayload(obj);
  return user;
};

const processRememberTokenLocal = () => {
  const token = LocalStorageTools.getValue("remember_token");
  if (token) return token;
  return "";
};

import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { Config } from "tools/utils/config";
import CloseIcon from "@mui/icons-material/Close";
import { MyButton } from "components/elements/button/MyButton";
import { useLabel } from "hooks/useLabel";
import { FilterList } from "components/product/filter/FilterList";
import { MyIcon } from "components/elements/icon/MyIcon";
import { IconType } from "components/elements/icon/IconContext";
import { FilterTools } from "components/product/filter/FilterTools";
import { useSearch } from "hooks/useSearch";

const Filters: React.FC = () => {
  const { LL } = useLabel();
  const {
    openFilterDrawer,
    closeFilterDrawer,
    handleOpenFilterDrawer,
    filterCriteriaLength,
    addCriteriaFilter,
    removeCriteriaFilter,
    criteriaFilter,
    removeRangeFilterMin,
    removeRangeFilterMax,
    replaceRangeFilterMin,
    replaceRangeFilterMax,
    rangeFilter,
    clearFilterCriteria,
  } = useSearch();

  const processFilterLength = () => {
    if (filterCriteriaLength) {
      return `(${filterCriteriaLength})`;
    } else {
      return "";
    }
  };

  return (
    <React.Fragment>
      {/* <MyButton
        className="flexJustifyCenter blackTransparentButtonStyle"
        sx={{
          gap: 1,
        }}
        cb={handleOpenFilterDrawer}>
        <MyIcon type={IconType.FILTER_ICON} />
        <Typography className="capitalizeText fontSizeGeneral">
          {LL("Filter")}
        </Typography>
        <Typography className="capitalizeText fontSizeGeneral">
          {processFilterLength()}
        </Typography>
      </MyButton> */}
      <MyButton
        className="filterDialogButton flexJustifyCenter productPageMoreInfo myButtonAnimation  "
        sx={{
          gap: 1,
        }}
        cb={handleOpenFilterDrawer}>
        <Box gap={"4px"} className="flexJustifyCenter textAnimation">
          <Typography className="filterDialogButtonText fontSizeGeneral ">
            {LL("Filters")}
          </Typography>
          <Typography className="filterDialogButtonText fontSizeGeneral">
            {processFilterLength()}
          </Typography>
        </Box>
      </MyButton>
      <Drawer
        open={openFilterDrawer}
        onClose={closeFilterDrawer}
        anchor={Config.DRAWER_ANCHOR}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}>
          <Box className="drawerCloseButton">
            <IconButton onClick={closeFilterDrawer}>
              <CloseIcon className="drawerSvg" />
            </IconButton>
          </Box>
        </Stack>
        <Box className="drawerFilter drawerWidth">
          <Box className="filterTools">
            <FilterTools
              clearFilterCriteria={clearFilterCriteria}
              filterCriteriaLength={filterCriteriaLength}
            />
          </Box>
          <Box className="filterList">
            <FilterList
              criteriaFilter={criteriaFilter}
              addCriteriaFilter={addCriteriaFilter}
              removeCriteriaFilter={removeCriteriaFilter}
              rangeFilter={rangeFilter}
              removeRangeFilterMin={removeRangeFilterMin}
              removeRangeFilterMax={removeRangeFilterMax}
              replaceRangeFilterMin={replaceRangeFilterMin}
              replaceRangeFilterMax={replaceRangeFilterMax}
              filterCriteriaLength={filterCriteriaLength}
            />
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export { Filters };

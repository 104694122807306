import { Box, Typography } from "@mui/material";
import { FAQAnswerDto } from "dto/static/faqanswer.dto";
import React from "react";

import { CommonTools } from "tools/utils/common.tool";
type Props = {
  item: FAQAnswerDto;
};

const FAQAnswerItem: React.FC<Props> = ({ item }) => {
  if (!item) return null;

  return (
    <Box>
      <Typography>{CommonTools.processObjectField(item, ["_name"])}</Typography>
    </Box>
  );
};

export { FAQAnswerItem };

import React from "react";

import { IconButton, IconButtonProps, SvgIconProps } from "@mui/material";
import { MyIcon } from "./MyIcon";

type Props = IconButtonProps & {
  typeIcon: string;
  svgProps?: SvgIconProps;
  colorSvg?: string;
  width?: string;
  height?: string;
};

const MyIconButton: React.FC<Props> = ({
  typeIcon,
  svgProps,
  colorSvg,
  width,
  height,
  ...props
}) => {
  return (
    <IconButton {...props}>
      <MyIcon
        type={typeIcon}
        {...svgProps}
        colorSvg={colorSvg}
        width={width}
        height={height}
      />
    </IconButton>
  );
};

export { MyIconButton };

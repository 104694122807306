import React from "react";

import { Box, IconButton } from "@mui/material";
import { useDrawer } from "hooks/useDrawer";
import CloseIcon from "@mui/icons-material/Close";
const DrawerCloseButton: React.FC = () => {
  const { closeAllDrawers } = useDrawer();

  return (
    <Box className="drawerCloseButton">
      <IconButton onClick={closeAllDrawers}>
        <CloseIcon className="drawerSvg" />
      </IconButton>
    </Box>
  );
};

export { DrawerCloseButton };

import React from "react";
import { Box } from "@mui/material";
import { IconType } from "components/elements/icon/IconContext";
import { MyIconButton } from "components/elements/icon/MyIconButton";
import { HeaderBlockProps } from "interfaces/header.interface";
import { useUser } from "hooks/useUser";
import { RouteTools } from "tools/utils/route.tool";

const WishBlock: React.FC<HeaderBlockProps> = ({ colorSvg }) => {
  const { user } = useUser();

  const handleClick = () => {
    RouteTools.setHistory("/profile", {}, "userwishlist");
  };
  if (!user) return null;
  return (
    <Box>
      <MyIconButton
        typeIcon={IconType.WISH}
        colorSvg={colorSvg}
        onClick={handleClick}
      />
    </Box>
  );
};

export { WishBlock };

import { useDrawer } from "hooks/useDrawer";
import React from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Skeleton,
} from "@mui/material";
import { Config } from "tools/utils/config";
import { CategoryProductDto } from "dto/product/categoryproduct.dto";
import { DrawerMainItem } from "./DrawerMainItem";
import { DrawerCloseButton } from "./DrawerCloseButton";
import { DrawerNavigator } from "components/static/menu/drawer/DrawerNavigator";
import { DrawerItemPhone } from "./DrawerItemPhone";
import { UserDrawerMenu } from "components/user/menu/UserDrawerMenu";
import Divider from "@mui/material/Divider";
import { SearchField } from "components/product/search/SearchField";
import { useResponsive } from "hooks/useResponsive";
import { LanguageSiteOptions } from "components/static/language/LanguageSiteOptions";

const MyDrawer: React.FC = () => {
  const { openMainDrawer, closeAllDrawers, mainCategoryObjects } = useDrawer();
  const { downMD } = useResponsive();
  const processMainItem = (item: CategoryProductDto, index: number) => {
    return <DrawerMainItem key={index} item={item} />;
  };
  const processMainList = () => {
    if (!mainCategoryObjects) return <Skeleton />;
    if (!mainCategoryObjects.length) return null;
    return mainCategoryObjects.map((item, index) =>
      processMainItem(item, index)
    );
  };

  return (
    <Drawer
      open={openMainDrawer}
      onClose={closeAllDrawers}
      anchor={Config.DRAWER_ANCHOR}>
      <Box className="drawerTopBlock">
        <Box className="drawerLanguageButton">
          <LanguageSiteOptions colorSvg={"black"} />
        </Box>
        <DrawerCloseButton />
      </Box>
      <Box className="drawerSearch">
        <SearchField handleAction={closeAllDrawers} />
      </Box>
      <Box className="drawerStyle">
        <List>{processMainList()}</List>
        <Box className="dividerBox">
          <Divider className="divider" />
        </Box>
        <UserDrawerMenu />
        <Box className="dividerBox">
          <Divider className="divider" />
        </Box>
        <DrawerNavigator />
        <List>
          <ListItem key="tel">
            <ListItemButton>
              <DrawerItemPhone />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export { MyDrawer };

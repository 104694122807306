import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { FAQDto } from "dto/static/faq.dto";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CommonTools } from "tools/utils/common.tool";
import { FAQAnswerList } from "./FAQAnswerList";
type Props = {
  item: FAQDto;
};

const FAQItem: React.FC<Props> = ({ item }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOnChange = () => {
    setOpen(!open);
  };

  if (!item) return null;

  return (
    <Accordion
      className="acordionStyle"
      expanded={open}
      onChange={handleOnChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <Typography className="acordionTitle">
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
      </AccordionSummary>
      {open && (
        <AccordionDetails>
          <FAQAnswerList id={CommonTools.processObjectField(item, ["id"])} />
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export { FAQItem };

import React, { useState } from "react";
import { Box } from "@mui/material";
import { IconType } from "components/elements/icon/IconContext";
import { MyIconButton } from "components/elements/icon/MyIconButton";
import { UserTopMenu } from "components/user/menu/UserTopMenu";
import { HeaderBlockProps } from "interfaces/header.interface";


const ProfileBlock: React.FC<HeaderBlockProps> = ({ colorSvg }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
      }}
    >
      <MyIconButton
        id="basic-button-user_top_menu"
        aria-controls={open ? "basic-menu-user_top_menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        typeIcon={IconType.USER}
        colorSvg={colorSvg}
      />
      <UserTopMenu open={open} handleClose={handleClose} anchorEl={anchorEl} />
    </Box>
  );
};

export { ProfileBlock };

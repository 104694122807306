import { Context, Actions, StateResource } from "contexts/cart.context";
import { DeliveryMethodOfferDto } from "dto/delivery/deliverymethodoffer.dto";
import { OfferDto } from "dto/product/offer.dto";
import { ProductDto } from "dto/product/product.dto";
import { ProductFilterDto } from "dto/product/productfilter.dto";
import { CartDto } from "dto/sale/cart.dto";
import { useMessage } from "providers/MessageProvider";

import { useMemo, useContext } from "react";
import { MessageTypes } from "tools/types/messagetypes";
import { useLabel } from "./useLabel";

type Props = () => {
  inCart: (id: string | number) => boolean;
  cartLength: number;
  addOrRemoveFromCart: (product: CartDto) => void;
  addToCart: (product: CartDto) => void;
  clearCart: () => void;
  removeFromCart: (product: CartDto) => void;
  addOrRemoveFromCartProductPage: (
    product: ProductDto,
    quantity: number,
    offer: OfferDto,
    selectedFilter: ProductFilterDto[]
  ) => void;
  addFromCartProductPage: (
    product: ProductDto,
    quantity: number,
    offer: OfferDto,
    selectedFilter: ProductFilterDto[]
  ) => void;
  changeQuantity: (id: string, quantity: number) => void;
  setDeliveryMethodOffer: (offer: DeliveryMethodOfferDto | null) => void;
  setQuantityCart: (value: number | string, item: CartDto) => void;
} & StateResource;

export const useCart: Props = () => {
  const { state, actions } = useContext(Context);

  const { cartObjects, deliveryMethodOffer } = state;
  const {
    addOrRemoveFromCart,
    addToCart,
    clearCart,
    removeFromCart,
    changeQuantity,
    setDeliveryMethodOffer,
  } = actions as Actions;
  const { registerMessage } = useMessage();
  const { LL } = useLabel();
  const cartLength = CartDto.getCartLength(cartObjects);
  const inCart = (id: string | number) =>
    cartObjects.some((item) => item.id === id);

  const addOrRemoveFromCartProductPage = (
    product: ProductDto,
    quantity: number,
    offer: OfferDto,
    selectedFilter: ProductFilterDto[]
  ) => {
    if (!product || !quantity || !offer) return;
    const object = CartDto.add(product, quantity, offer, selectedFilter);
    if (!object) return;
    addOrRemoveFromCart(object);
  };

  const addFromCartProductPage = (
    product: ProductDto,
    quantity: number,
    offer: OfferDto,
    selectedFilter: ProductFilterDto[]
  ) => {
    if (!product || !quantity || !offer) return;
    const object = CartDto.add(product, quantity, offer, selectedFilter);
    if (!object) return;
    addToCart(object);
  };

  const setQuantityCart = (value: number | string, item: CartDto) => {
    if (!value) return;
    const parseValue = parseInt(value.toString());
    if (parseValue < 1) return;
    if (
      OfferDto.getOfferByQuantityAndFilters(
        parseValue,
        ProductFilterDto.arrProductFilterToSelectedProductFilterDto(
          item.filters
        ),
        [item.offer]
      ) === null
    ) {
      registerMessage(
        LL("error_checkout_offer_change_quantity"),
        MessageTypes.MESSAGE_ERROR,
        "404"
      );
      return;
    }
    changeQuantity(item.id, parseValue);
  };

  return useMemo(
    () => ({
      cartObjects,
      inCart,
      cartLength,
      addOrRemoveFromCart,
      addToCart,
      clearCart,
      removeFromCart,
      addOrRemoveFromCartProductPage,
      addFromCartProductPage,
      changeQuantity,
      deliveryMethodOffer,
      setDeliveryMethodOffer,
      setQuantityCart
    }),
    [cartObjects, cartLength, deliveryMethodOffer]
  );
};

import { Box } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { FilterDto } from "dto/product/filter.dto";
import { useList } from "hooks/useList";
import React from "react";
import { FilterService } from "services/product/filter.service";
import { Status } from "tools/types/status";
import { FilterItem } from "./FilterItem";
import { SkeletonMenuFilterOptions } from "components/elements/skeleton/SkeletonMenuFilter";
import { RequestCriteriaDTO } from "dto/app/requestcriteria.dto";

const service = new FilterService();

type Props = {
  addCriteriaFilter: (id: string, value: string) => void;
  removeCriteriaFilter: (id: string, value: string) => void;
  criteriaFilter: RequestCriteriaDTO[];
  removeRangeFilterMin: (id: string) => void;
  removeRangeFilterMax: (id: string) => void;
  replaceRangeFilterMin: (id: string, value: string) => void;
  replaceRangeFilterMax: (id: string, value: string) => void;
  rangeFilter: RequestCriteriaDTO[];
  filterCriteriaLength: number;
};
const FilterList: React.FC<Props> = ({
  addCriteriaFilter,
  removeCriteriaFilter,
  criteriaFilter,
  removeRangeFilterMin,
  removeRangeFilterMax,
  replaceRangeFilterMin,
  replaceRangeFilterMax,
  rangeFilter,
  filterCriteriaLength,
}) => {
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<FilterDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    ),
    [],
    true,
    `filter-product-list`
  );

  const processItem = (item: FilterDto, index: number) => {
    if (!item) return null;
    return (
      <Box className="filterList" key={index}>
        <FilterItem
          item={item}
          addCriteriaFilter={addCriteriaFilter}
          removeCriteriaFilter={removeCriteriaFilter}
          criteriaFilter={criteriaFilter}
          removeRangeFilterMin={removeRangeFilterMin}
          removeRangeFilterMax={removeRangeFilterMax}
          replaceRangeFilterMin={replaceRangeFilterMin}
          replaceRangeFilterMax={replaceRangeFilterMax}
          rangeFilter={rangeFilter}
          filterCriteriaLength={filterCriteriaLength}
        />
      </Box>
    );
  };
  if (loading)
    return (
      <Box sx={{ px: 2 }}>
        <SkeletonMenuFilterOptions lines={2} />
      </Box>
    );
  if (!objects) return null;
  return (
    <Box>
      {objects.map((item: FilterDto, index: number) =>
        processItem(item, index)
      )}
    </Box>
  );
};

export { FilterList };

import React, { useEffect, useState } from "react";

import { IPage } from "interfaces/page.interface";
import { Box } from "@mui/material";
import { useProduct } from "hooks/useProduct";
import { CommonTools } from "tools/utils/common.tool";
import { ProductShortInfo } from "components/product/product/page/ProductShortInfo";
import { DetailItem } from "components/product/product/page/DetailItem";
import { HtmlTag } from "components/elements/display/HtmlTag";
import { ProductBreadcrumb } from "components/product/product/page/ProductBreadcrumb";
import { ProductMedia } from "components/product/product/page/ProductMedia";
import { ButtonAddToCart } from "components/product/product/page/ButtonAddToCart";
import { ProductFilterList } from "components/product/productfilter/ProductFilterList";
import { useLabel } from "hooks/useLabel";
import { Config } from "tools/utils/config";
import { logger } from "tools/utils/logger";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { ProductDialogButton } from "./ProductDialog";

const ProductPage: React.FC<IPage> = ({ currentRoute }) => {
  const {
    get,
    product,
    loadingProduct,
    resetState,
    getProductFilters,
    getOffers,
    getSelectedOffer,
  } = useProduct();
  const id = CommonTools.processIdFromPaths(currentRoute, 2);
  const { LL } = useLabel();
  const [pages, setPages] = React.useState(1);
  const [photoTotalHeight, setPhotoTotalHeight] = React.useState(1);
  const [photoNR, setPhotoNR] = React.useState(0);
  const [startHeight, setStartHeight] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const footerHeight = 90;

  useEffect(() => {
    setPhotoTotalHeight((product?._galleryActiveList?.length || 0) * 960);
    setPhotoNR(product?._galleryActiveList?.length || 0);
  }, [product?._galleryActiveList]);

  useEffect(() => {
    const photoNumber = (product?._galleryActiveList?.length || 0) - 1;
    const viewportHeight = window.innerHeight;
    if (viewportHeight > photoNR * 1200) {
      setPages(1);
    } else if (viewportHeight > 1200) {
      setPages(1 + photoNumber * 0.402);
    } else if (photoNR > 1 && viewportHeight < photoNR * 1200) {
      setPages(1 + photoNumber * 0.402);
    } else {
      setPages(1);
    }
  }, [photoTotalHeight]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    if (currentRoute?._mainobject !== "product") return;
    if (!id) return;
    get(id);
  }, [get, id]);

  useEffect(() => {
    getProductFilters();
  }, [getProductFilters]);

  useEffect(() => {
    getOffers();
  }, [getOffers]);

  useEffect(() => {
    getSelectedOffer();
  }, [getSelectedOffer]);

  useEffect(() => {
    if (photoNR > 0) {
      setStartHeight(photoNR * 460 + 600);

      const intervalId = setInterval(() => {
        // logger("startHeight", startHeight);
        const childDiv = document.querySelector(
          ".paralax1 > div"
        ) as HTMLElement;

        if (childDiv) {
          setTimeout(() => {
            childDiv.style.setProperty(
              "max-height",
              `calc(${photoNR * 460 + 600}px + (calc(100vh - 1000px) * 0.605))`,
              "important"
            );
            clearInterval(intervalId);
          }, 200);
        }
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, [photoNR, startHeight]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.offsetHeight;
      const parallax = document.querySelector(".paralax1") as HTMLElement;
      if (bodyHeight - (scrollPosition + windowHeight) < footerHeight) {
        if (parallax) {
          parallax.style.overflow = "hidden";
        }
      } else {
        if (parallax) {
          parallax.style.overflow = "auto";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!currentRoute) return null;
  if (!id) return null;
  if (loadingProduct) return null;
  if (!product) return null;

  const alignCenter = { display: "flex", alignItems: "start" };

  return (
    //paralax
    <Box sx={{ height: "calc(100vh + 70px)" }}>
      <Box className="productTopBlock">
        <ProductBreadcrumb />
      </Box>
      <Parallax key={pages} pages={pages} style={{}} className="paralax1">
        <ParallaxLayer
          sticky={{ start: 0, end: 5 }}
          offset={0}
          style={{
            ...alignCenter,
            justifyContent: "flex-end",
            width: "50vw",
            inset: "0px 50vw",
          }}>
          <Box className="productPageInfo" sx={{}}>
            <Box className="productPageInfoBox">
              <ProductShortInfo item={product} />
              <ProductDialogButton product={product} />
            </Box>
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          offset={0}
          speed={1.5}
          className="productPagePhoto"
          style={{
            maxHeight: "300px",
            ...alignCenter,
            justifyContent: "flex-start",
            width: "50vw",
            inset: "0px 0px",
          }}>
          <Box className="productPagePhoto">
            <ProductMedia />
          </Box>
        </ParallaxLayer>
      </Parallax>
    </Box>
  );
};

export { ProductPage };

import { Box, Typography } from "@mui/material";
import React from "react";
import { useLabel } from "hooks/useLabel";
import { PayMethodList } from "components/static/paymethod/PayMethodList";

const PaymentBlock: React.FC = () => {
  const { LL } = useLabel();

  return (
    <Box>
      <Typography className="bottomMeuLabelsText">
        {LL("payment_block")}
      </Typography>
      <PayMethodList />
    </Box>
  );
};

export { PaymentBlock };

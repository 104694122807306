import { ProductWishlistService } from "services/product/productwishlist.service";
import { useUser } from "./useUser";
import { CommonTools } from "tools/utils/common.tool";
import useObject from "./useObject";
import {
  IsInWishlistDto,
  ProductWishlistDto,
} from "dto/product/productwishlist.dto";
import { useMemo } from "react";

type Props = (idProduct: string) => {
  loading: boolean;
  object: IsInWishlistDto | null;
  inWishlist: boolean;
  addInWishlist: () => void;
  removeFromWishlist: () => void;
};

const service = new ProductWishlistService();
export const useProductWishlist: Props = (idProduct) => {
  const { user } = useUser();
  const idUser = CommonTools.processObjectField(user, ["id"]);
  const get = (id: string, cb?: any, cbparameters?: any) => {
    if (!idUser) return;
    service.getIsInWishList(idUser, id, cb, cbparameters);
  };

  const [loading, object, getObject] = useObject<IsInWishlistDto>(
    get,
    idProduct,
    [idProduct, idUser]
  );

  const addInWishlist = () => {
    const obj = new ProductWishlistDto(
      undefined,
      parseInt(idProduct),
      parseInt(idUser)
    );
    service.add(getObject, {}, obj);
  };

  const removeFromWishlist = () => {
    if (!object) return;
    const id = CommonTools.processObjectField(object, ["id"]);
    if (!id) return;
    service.delete(id, getObject, {});
  }

  const inWishlist = CommonTools.processNumberToBoolean(
    parseInt(CommonTools.processObjectField(object, ["inwishlist"]))
  );
  
  return useMemo(
    () => ({
      loading,
      object,
      inWishlist,
      addInWishlist,
      removeFromWishlist,
    }),
    [loading, object, inWishlist]
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
  identifier: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
};

const DetailItem: React.FC<Props> = ({
  identifier,
  children,
  defaultOpen = false,
}) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Accordion onChange={handleClick} expanded={open} sx={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <Typography>{identifier}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export { DetailItem };

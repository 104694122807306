import { Box, Stack, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { useLabel } from "hooks/useLabel";
import React from "react";

type Props = {
  filterCriteriaLength: number;
  clearFilterCriteria: () => void;
};
const FilterTools: React.FC<Props> = ({
  filterCriteriaLength,
  clearFilterCriteria,
}) => {
  const { LL } = useLabel();

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      alignContent={"center"}
      spacing={4}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          gap: 1,
        }}>
        <Typography className="fontSizeGeneralBold">{LL("Filters")}</Typography>
        <Typography className="fontSizeGeneralBold">
          ({filterCriteriaLength})
        </Typography>
      </Box>
      <Box>
        <MyButton
          className="flexJustifyCenter blackTransparent2ButtonStyle"
          cb={clearFilterCriteria}>
          <Typography className="fontSizeGeneralBold" sx={{ mt: "2px" }}>
            {LL("Clear")}
          </Typography>
        </MyButton>
      </Box>
    </Stack>
  );
};

export { FilterTools };

import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class IPRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("ip");
  }

  get = async (cb?: any, cbParams?: any) => {
    const url = `${this.mainUrl}/info`;
    return await this.getObject(url, cb, cbParams);
  }
}

import { Box } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { FAQAnswerDto } from "dto/static/faqanswer.dto";
import { useList } from "hooks/useList";
import React from "react";
import { FAQAnswerService } from "services/static/faqanswer.service";
import { Status } from "tools/types/status";

import { FAQAnswerType } from "tools/types/faqanswertype";
import { FAQAnswerItem } from "./FAQAnswerItem";

type Props = {
  id: string ;
};
const service = new FAQAnswerService();
const FAQAnswerList: React.FC<Props> = ({ id }) => {
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  const [loading, objects] = useList<FAQAnswerDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("type", [FAQAnswerType.GENERAL.toString()]),
        RequestFilterDTO.prepareFilter("idfaq", [id]),
      ],
      1,
      -1
    ),
    [id]
  );
  const processItem = (item: FAQAnswerDto, index: number) => {
    if (!item) return null;
    return <FAQAnswerItem item={item} key={index} />;
  };
  
  if(!id) return null;
  if (loading) return null;
  if (!objects) return null;
  return (
    <Box>
      {objects.map((item: FAQAnswerDto, index: number) => processItem(item, index))}
    </Box>
  );
};

export { FAQAnswerList };

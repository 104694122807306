import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Typography,
} from "@mui/material";
import { useLabel } from "hooks/useLabel";
import { MyButton } from "components/elements/button/MyButton";
import { Box } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { LinkToCookiePolicy } from "components/static/page/block/LinkToCookiePolicy";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type Props = {
  open: boolean;
  handleClose: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CookieInfoDialog: React.FC<Props> = ({ open, handleClose }) => {
  const { LL } = useLabel();
  return (
    <Box className="flexJustifyCenter ">
      <Dialog
        open={open}
        keepMounted
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="md"
        aria-describedby="alert-dialog-slide-description"
        className="cookiesBox">
        <DialogTitle className="dialogTitle">
          {LL("cookie_info_title")}
        </DialogTitle>
        <DialogContent className="dialogContent">
          <DialogContentText
            className="dialogText"
            sx={{ mb: 3 }}
            id="alert-dialog-slide-description">
            {LL("cookie_info_text")}
          </DialogContentText>

          <Box component="span" className="dialogText">
            <Typography sx={{ display: "inline", mr: "3px" }}>
              {LL("cookie_info_text2")}
            </Typography>
            <LinkToCookiePolicy />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className=" listItemButton standardButtonSize whiteButton ">
            <MyButton
              cb={handleClose}
              className=" buttonWithLoading flexJustifyCenter">
              <Typography className="textButton">{LL("bt_accept")}</Typography>
              <Box className="arrowSvg flexJustifyCenter ">
                <KeyboardArrowRightIcon className="iconSvg2" />
              </Box>
            </MyButton>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export { CookieInfoDialog };

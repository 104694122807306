import { Box, Typography } from "@mui/material";
import { BlogDto } from "dto/static/blog.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Image } from "components/elements/display/Image";
import { NavButton } from "components/elements/button/NavButton";
import { useLabel } from "hooks/useLabel";

type Props = {
  item: BlogDto;
};
const BlogItemHome: React.FC<Props> = ({ item }) => {
  const { LL } = useLabel();
  const processImage = () => {
    if (!item) return null;
    if (!CommonTools.processObjectField(item, ["_galleryDefault", "cdnurl"]))
      return null;
    return (
      <Box
        sx={{
          width: "100%",
          height: { xs: "400px", md: "584px" },
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Image
          url={CommonTools.processObjectField(item, [
            "_galleryDefault",
            "cdnurl",
          ])}
          typeMeasure="percentage"
          sizeInPercentage={{ width: "100%", height: "100%" }}
          withLinK={false}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
            position: "relative",
          }}
        />
      </Box>
    );
  };
  if (!item) return null;
  return (
    <Box>
      <Box>{processImage()}</Box>
      <Box sx={{ mt: 1 }}>
        <Typography
          sx={{
            fontSize: { xs: "20px", md: "32px" },
            fontWeight: "bold",
            color: "white",
          }}>
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
      </Box>
      <Box>
        <NavButton
          href={CommonTools.processObjectField(item, ["_urlObj", "urlfull"])}>
          {LL("bt_view_detail")}
        </NavButton>
      </Box>
    </Box>
  );
};

export { BlogItemHome };

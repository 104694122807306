import { Box, Typography } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyTextFieldConfig } from "components/elements/form/MyTextFieldConfig";

import { UserDto } from "dto/user/user.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React, { useState } from "react";
import { UserConfigType } from "tools/types/userconfigtype";

import IValidator from "interfaces/validator.interface";
import { CallbackType } from "interfaces/commontypes.interface";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type Props = {
  defaultObject: UserDto;
  onSubmit: (data: UserDto, cb: CallbackType) => void;
};
const FormUser: React.FC<Props> = ({ defaultObject, onSubmit }) => {
  const { LL } = useLabel();
  const validators: IValidator[] = [];
  const [loading, setLoading] = useState(false);
  const [obj, disabled, _, setObjConfig] = useForm<UserDto>(
    defaultObject,
    validators
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    onSubmit(obj, cb);
  };

  const cb = () => {
    setLoading(false);
  };
  return (
    <Box className="authForm">
      <form onSubmit={handleSubmit}>
        <Box mt={3}>
          <MyTextFieldConfig
            field="configs"
            sx={{ width: "100%" }}
            label={LL("field_first_name")}
            setObjectField={setObjConfig}
            keyConfig={UserConfigType.FIRST_NAME}
            arrConfig={obj.configs}
            selectTheme="standardText"
          />
        </Box>
        <Box mt={3}>
          <MyTextFieldConfig
            field="configs"
            sx={{ width: "100%" }}
            label={LL("field_second_name")}
            setObjectField={setObjConfig}
            keyConfig={UserConfigType.SECOND_NAME}
            arrConfig={obj.configs}
            selectTheme="standardText"
          />
        </Box>
        <Box mt={3} className="authButton">
          <Box
            className={`standardButtonSize whiteButton listItemButton ${
              disabled ? "disabled" : ""
            } ${loading ? "loadingBtn" : ""}
            `}>
            <ButtonWithLoading
              type="submit"
              disabled={loading || disabled}
              loading={loading}
              className="buttonWithLoading flexJustifyCenter">
              <Typography className="textButton" sx={{}}>
                {LL("btn_submit")}
              </Typography>
              <Box className="arrowSvg flexJustifyCenter ">
                {!disabled && <KeyboardArrowRightIcon className="iconSvg3" />}
              </Box>
            </ButtonWithLoading>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export { FormUser };

import React, { useState } from "react";
import { IPage } from "interfaces/page.interface";
import { ForgotPasswordForm } from "components/authentication/ForgotPasswordForm";
import { Box, Container, Typography } from "@mui/material";

import { Config } from "tools/utils/config";
import { ForgotPasswordDto } from "dto/user/user.dto";
import { UserService } from "services/user/user.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/utils/common.tool";
import { useLabel } from "hooks/useLabel";
import { NavButton } from "components/elements/button/NavButton";
import { useSeoInfo } from "hooks/useSeoInfo";

const service = new UserService();
const ForgotPasswordPage: React.FC<IPage> = ({ currentRoute }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { LL } = useLabel();
  const handleResult = (result: ResultObjectDTO) => {
    setLoading(false);
    if (!result) return;
    if (result.error) return;
    setSuccess(true);
  };
  const onSubmit = (obj: ForgotPasswordDto) => {
    setLoading(true);
    service.forgotPassword(handleResult, {}, obj);
  };
  useSeoInfo("forgotpassword");
  return (
    <Box className="authMaineBox" sx={{}}>
      <Container maxWidth="sm" className="authContainer">
        <Box className={"authBox"}>
          {success ? (
            <Box>
              <Box sx={{ textAlign: "center" }}>
                <Typography sx={{ fontSize: "20px" }}>
                  {LL("forgotpassword_info_sent")}
                </Typography>
              </Box>
              <Box
                // className="myButtonAnimation"
                sx={{ textAlign: "center", mt: 4 }}>
                <NavButton
                  sx={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "500",
                    textAlign: "center",
                    borderBottom: "1px solid black",
                    "&:hover": {
                      borderBottom: "1px solid white",
                    },
                  }}
                  className={"textAnimation"}
                  href="/">
                  {LL("returnToHomepage")}
                </NavButton>
              </Box>
            </Box>
          ) : (
            <ForgotPasswordForm
              onSubmit={onSubmit}
              defaultObj={
                new ForgotPasswordDto(
                  CommonTools.processObjectField(currentRoute, [
                    "historystate",
                    "email",
                  ])
                )
              }
              loading={loading}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export { ForgotPasswordPage };

import { Box, Typography } from "@mui/material";

import { useLabel } from "hooks/useLabel";
import React from "react";
import { IconType } from "../icon/IconContext";
import { MyIcon } from "../icon/MyIcon";

type NoResultProps = {
  identifier: string;
};

const NoResult: React.FC<NoResultProps> = ({ identifier }) => {
  const { LL } = useLabel();

  return (
    <Box className="staticMaineBox flexJustifyCenter noResult">
      <Box>
        <MyIcon
          type={IconType.NO_RESULT}
          width="100%"
          height="100%"
          colorFill="gray"
        />
      </Box>
      <Box className="boxNoResult">
        <Typography className="noResultText">
          {LL("no_result_" + identifier)}
        </Typography>
        <Typography className="noResultText2">
          {LL("we_couldn't_find_anything")}
        </Typography>
      </Box>
    </Box>
  );
};

export { NoResult };

import { Box, ListItem, ListItemButton, Typography } from "@mui/material";
import { UserMenuType } from "dto/user/user.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type Props = {
  item: UserMenuType;
};

const UserDrawerMenuItem: React.FC<Props> = ({ item }) => {
  const { LL } = useLabel();
  const handleOnClick = () => {
    if (item.onClick) {
      item.onClick(item.url, item.anchor);
    }
  };

  if (!item) return null;
  return (
    <ListItem>
      <ListItemButton onClick={handleOnClick} className="listItemButton">
        <Typography className="fontSizeGeneral">{LL(item.label)}</Typography>
        <Box>
          <KeyboardArrowRightIcon className="iconSvg" />
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export { UserDrawerMenuItem };

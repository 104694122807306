import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { DictionaryDTO } from "dto/app/dictionary.dto";

type Props = TextFieldProps & {
  field: string;
  label: string;
  setObjectField: (field: string, key: number, value: any) => void;
  keyConfig: number;
  selectTheme?: string;

  arrConfig?: Array<DictionaryDTO>;
};

const MyTextFieldConfig: React.FC<Props> = ({
  label,
  field,
  setObjectField,
  keyConfig,
  arrConfig,
  selectTheme,
  ...props
}) => {
  const handleChange = (event: any) => {
    // logger("MyTextFieldConfig",field,keyConfig, event.target.value)
    setObjectField(field, keyConfig, event.target.value);
  };

  const processValue = () => {
    if (arrConfig) {
      const index = arrConfig.findIndex((x) => x.key === keyConfig);
      if (index !== -1) {
        return arrConfig[index].value;
      }
    }
    return "";
  };

  return (
    <TextField
      label={label}
      onFocus={handleChange}
      onChange={handleChange}
      value={processValue()}
      className={selectTheme}
      {...props}
    />
  );
};

export { MyTextFieldConfig };

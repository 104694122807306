import { Box } from "@mui/material";
import { NavButton } from "components/elements/button/NavButton";
import { useLabel } from "hooks/useLabel";
import { usePage } from "hooks/usePage";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";

const LinkToAboutUs: React.FC = () => {
  const id = Config.ID_ABOUT_US_PAGE;
  const { object, loading } = usePage(id, true, "link_to_about_us");
  const { LL } = useLabel();
  if (!id) return null;
  if (loading) return null;
  if (!object) return null;
  if (!CommonTools.processObjectField(object, ["_urlObj", "urlfull"]))
    return null;
  return (
    <Box sx={{ width: 200 }}>
      <NavButton
        buttonTheme="goldenBtn"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        href={CommonTools.processObjectField(object, ["_urlObj", "urlfull"])}>
        {LL("View_more")}
      </NavButton>
    </Box>
  );
};

export { LinkToAboutUs };

import React from "react";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import SelectOptions from "dto/app/selectoptions.dto";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import FormControl from "@mui/material/FormControl/FormControl";
import { useLabel } from "hooks/useLabel";

type Props = SelectProps & {
  options: Array<SelectOptions>;
  setValue: (value: any) => void;
  value: any;
  _label: string;
  showLabel?: boolean;
  selectTheme?: any;
};

const GeneralSelect: React.FC<Props> = ({
  options,
  setValue,
  value,
  _label,
  showLabel = true,
  selectTheme,

  ...props
}) => {
  const { LL } = useLabel();

  const label = _label !== undefined ? LL(_label) : LL("select_label");

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };
  const processOptions = () => {
    if (!options)
      return (
        <MenuItem value="" disabled>
          {LL("No_Data")}
        </MenuItem>
      );
    if (options.length === 0)
      return (
        <MenuItem value="" disabled>
          {LL("No_Data")}
        </MenuItem>
      );
    return options.map((item, index) => (
      <MenuItem key={index} value={item.value}>
        {item.label}
      </MenuItem>
    ));
  };

  return (
    <FormControl fullWidth sx={{ height: "auto" }} className="mySelect">
      {showLabel && (
        <InputLabel className={selectTheme} id="select-label">
          {label}
        </InputLabel>
      )}
      <Select
        className={selectTheme}
        labelId="select-label"
        id="simple-select"
        color="primary"
        variant="filled"
        size="small"
        onChange={handleChange}
        value={value !== undefined && value !== 0 ? value : ""}
        label={label}
        {...props}>
        {processOptions()}
      </Select>
    </FormControl>
  );
};

export { GeneralSelect };

import { Box, Stack, SxProps, Typography } from "@mui/material";
import { NavButton } from "components/elements/button/NavButton";
import { Image } from "components/elements/display/Image";
import { VideoFile } from "components/elements/display/VideoFile";

import { CategoryProductDto } from "dto/product/categoryproduct.dto";
import { useLabel } from "hooks/useLabel";
import React, { useEffect } from "react";
import { CommonTools } from "tools/utils/common.tool";
import { logger } from "tools/utils/logger";
import { RouteTools } from "tools/utils/route.tool";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type Props = {
  item: CategoryProductDto;
  index: number;
};

const CategoryProductItemHome: React.FC<Props> = ({ item, index }) => {
  const { LL } = useLabel();
  // const styleBlock: SxProps =
  //   index === 0
  //     ? {
  //         width: "auto",
  //         height: "100%",
  //         boxSize: "content-box",
  //         overflow: "hidden",
  //         position: "relative",
  //         "::after": {
  //           content: '""',
  //           position: "absolute",
  //           top: 0,
  //           right: 0,
  //           bottom: 0,
  //           left: 0,
  //           border: "10px solid white",
  //           boxSizing: "border-box",
  //           pointerEvents: "none",
  //         },
  //         cursor: "pointer",
  //         // px: 2,
  //         // py: 1,s
  //       }
  //     : {
  //         width: "auto",
  //         height: "100%",
  //         overflow: "hidden",
  //         position: "relative",

  //         "::after": {
  //           content: '""',
  //           position: "absolute",
  //           top: 0,
  //           right: 0,
  //           bottom: 0,
  //           left: 0,
  //           border: "10px solid white",
  //           boxSizing: "border-box",
  //           pointerEvents: "none",
  //         },
  //         cursor: "pointer",
  //       };

  const processImage = () => {
    if (!item) return null;
    if (!CommonTools.processObjectField(item, ["_galleryDefault", "cdnurl"]))
      return null;
    return (
      <Box className="imageDiv categoryProductItemHome">
        <Image
          url={CommonTools.processObjectField(item, [
            "_galleryDefault",
            "cdnurl",
          ])}
          typeMeasure="percentage"
          sizeInPercentage={{ width: "105%", height: "105%" }}
          withLinK={false}
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
    );
  };

  const processMedia = () => {
    if (!item) return null;
    if (
      CommonTools.processObjectField(item, ["_attachmentDefault", "cdnurl"])
    ) {
      return (
        <Box className="videoDiv" sx={{ width: "100%", height: "100%" }}>
          <VideoFile
            url={CommonTools.processObjectField(item, [
              "_attachmentDefault",
              "cdnurl",
            ])}
            //   light={processImage() !== null ? processImage() : false}
            controls={false}
            width="105%"
            height="105%"
            style={{ objectFit: "cover" }}
          />
        </Box>
      );
    } else {
      return processImage();
    }
  };

  const navigate = () => {
    const url = CommonTools.processObjectField(item, ["_urlObj", "urlfull"]);
    if (!url) return;
    RouteTools.setHistory(url, {});
  };

  if (!item) return null;
  return (
    <Box
      className="addBlocks categoryProductItemHome"
      sx={[
        // styleBlock,
        { position: "relative" },
      ]}
      onClick={navigate}>
      {processMedia()}
      <Box className="categoryProductItemHomeBox">
        <Typography
          data-aos="zoom-in"
          data-aos-delay="50"
          className="categoryProductItemHomeTitle">
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
        <Stack
          data-aos="zoom-in"
          data-aos-delay="100"
          alignItems={"center"}
          justifyContent={"center"}>
          <Box className="myButtonAnimation2">
            <NavButton
              buttonTheme="textAnimation"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              href={CommonTools.processObjectField(item, [
                "_urlObj",
                "urlfull",
              ])}>
              {LL("bt_discover")}
              <KeyboardArrowRightIcon
                className=""
                sx={{ mt: "2px", ml: "3px" }}
              />
            </NavButton>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export { CategoryProductItemHome };

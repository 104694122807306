import SelectOptions from "dto/app/selectoptions.dto";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { Status } from "tools/types/status";

export class CurrencyDto implements Idto {
  id?: number | string;
  _name?: string;
  nameofficial?: string;
  code?: string;
  symbol?: string;
  _idlang?: number | string;
  html_code?: string;
  order?: number;
  status?: number;
  urlprefix?: string;
  unitfraction?: string;

  constructor(
    _idlang?: number | string,
    id?: number | string,
    _name?: string,
    nameofficial?: string,
    html_code?: string,
    code?: string,
    symbol?: string,
    order?: number,
    status?: number,
    urlprefix?: string,
    unitfraction?: string
  ) {
    this.id = id || 0;
    this._name = _name || "";
    this.nameofficial = nameofficial || "";
    this.html_code = html_code || "";
    this.order = order || 0;
    this.status = status || Status.ACTIVE;
    this.urlprefix = urlprefix || "";
    this.code = code || "";
    this.symbol = symbol || "";
    this._idlang = _idlang || 0;
    this.unitfraction = unitfraction || "";
  }

  static parseToSelectOptions(data: Array<CurrencyDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          `${CommonTools.processObjectField(element, [
            "symbol"
          ])} ${CommonTools.processObjectField(element, ["nameofficial"])}`
        )
      );
    });
    return result;
  }
}

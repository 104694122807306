import { ProductWishlistDto } from "dto/product/productwishlist.dto";
import React from "react";
import { ProductItem } from "../product/list/ProductItem";

type Props = {
  object: ProductWishlistDto;
};

const UserWishlistItem: React.FC<Props> = ({ object }) => {
  if (!object) return null;
  if (!object.productObj) return null;
  return <ProductItem item={object.productObj} />;
};

export { UserWishlistItem };

import { OrderDto } from "dto/sale/order.dto";

import React from "react";
import { ClientType } from "tools/types/clienttype";
import { PaymentIndividual } from "./PaymentIndividual";
import { PaymentLegalPerson } from "./PaymentLegalPerson";
import { Box, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { useLabel } from "hooks/useLabel";
import { useCountry } from "hooks/useCountry";
import { DateTools } from "tools/utils/date.tool";

type Props = {
  object: OrderDto;
};

const PaymentInfo: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  const { currencyRate, currencyCode } = useCountry();
  const processClientInfo = () => {
    if (object.client_type === ClientType.INDIVIDUAL)
      return <PaymentIndividual object={object} />;
    if (object.client_type === ClientType.LEGALPERSON)
      return <PaymentLegalPerson object={object} />;
    return null;
  };

  const processPrice = (price: number | string) => {
    return `${price} ${currencyCode}`;
  };
  const processDate = () => {
    if (!object) return null;
    if (!object.datepayment) return null;
    return (
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("datepayment")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {" "}
          {DateTools.displayTimestampInDateAndHHMM(object.datepayment)}
        </Typography>
      </Box>
    );
  };
  if (!object) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        {processClientInfo()}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        {processDate()}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("paymethod")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["paymethod_name"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("paystatus")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["paystatus_name"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("pricedue")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {processPrice(
            CommonTools.convertPrice(
              CommonTools.processObjectField(object, ["pricedue"]),
              currencyRate
            )
          )}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("realpricetotal")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {" "}
          {processPrice(
            CommonTools.convertPrice(
              CommonTools.processObjectField(object, ["realpricetotal"]),
              currencyRate
            )
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export { PaymentInfo };

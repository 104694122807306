import React from "react";
import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useLabel } from "hooks/useLabel";

const ServerError: React.FC = () => {
  const { LL } = useLabel();
  const handleReload = () => {
    window.location.reload();
  };
  return (
    <Box className="dialogPages">
      <Box className="errorCodeText">500</Box>
      <Box className="errorText">Server Error</Box>
      <Box mt={3} className=" listItemButton standardButtonSize whiteButton ">
        <MyButton
          cb={handleReload}
          className=" buttonWithLoading flexJustifyCenter">
          <Typography className="textButton">Reload Page</Typography>
          <Box className="arrowSvg flexJustifyCenter ">
            <KeyboardArrowRightIcon className="iconSvg2" />
          </Box>
        </MyButton>
      </Box>
    </Box>
  );
};

export { ServerError };

import { GeneralSelect } from "components/elements/select/GeneralSelect";
import { LanguageDto } from "dto/static/language.dto";

import { useLanguage } from "providers/LanguageProvider";
import React from "react";

type Props = {
  showLabel?: boolean;
  handleOnChange?: (code: string) => void;
  selectTheme?: string;
};
const SelectSiteLanguage: React.FC<Props> = ({
  showLabel = true,
  handleOnChange,
  selectTheme,
}) => {
  const { selectedLanguageCode, changeLanguage, languageSiteObjects } =
    useLanguage();
  if (!handleOnChange) handleOnChange = changeLanguage;
  
  return (
    <GeneralSelect
      options={LanguageDto.parseToSelectOptions(languageSiteObjects, "code")}
      value={selectedLanguageCode}
      setValue={handleOnChange}
      _label="language"
      showLabel={showLabel}
      selectTheme={selectTheme}
    />
  );
};

export { SelectSiteLanguage };

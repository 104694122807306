import {
  Context,
  Actions,
  StateResource,
} from "contexts/categoryproduct.context";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { CategoryProductDto } from "dto/product/categoryproduct.dto";
import { ICurrentRoute } from "interfaces/currentroute.interface";
import { useMemo, useContext, useCallback } from "react";
import { useCookies } from "react-cookie";
import { Status } from "tools/types/status";

import { RouteTools } from "tools/utils/route.tool";

type Props = () => {
  getCategoryProduct: () => void;
  setIdCategory: (idCategory: string) => void;
  setCategoryProduct: (categoryProduct: CategoryProductDto | null) => void;
  getBreadcrumb: () => void;
  getProducts: (mainUrl: string, mainObject: string) => void;
  setPageProducts: (page: number) => void;
  setOnPageProducts: (onPage: number) => void;
  setFilters: (filters: RequestFilterDTO[]) => void;
  setSortCriteria: (sortCriteria: RequestSortCriteriaDTO | null) => void;
  setRequestList: (
    page: number,
    onPage: number,
    filters: RequestFilterDTO[],
    sortCriteria: RequestSortCriteriaDTO | null
  ) => void;
  getDefaultFilters: (currentRoute: ICurrentRoute | null) => void;
  handleOpenFilterDrawer: () => void;
  closeFilterDrawer: () => void;
  getBreadcrumbCategory: (codeCategory: string) => void;
  resetState: () => void;
  addCriteriaFilter: (id: string, value: string) => void;
  removeCriteriaFilter: (id: string, value: string) => void;
  replaceCriteriaFilter: (id: string, value: string) => void;
  filterCriteriaLength: number;
  clearFilterCriteria: () => void;
  removeRangeFilterMin: (id: string) => void;
  removeRangeFilterMax: (id: string) => void;
  replaceRangeFilterMin: (id: string, value: string) => void;
  replaceRangeFilterMax: (id: string, value: string) => void;
} & StateResource;

export const useCategoryProduct: Props = () => {
  const { state, actions } = useContext(Context);
  const [cookies] = useCookies();
  const {
    categoryProduct,
    idCategory,
    productList,
    totalPagesProducts,
    totalProducts,
    pageProducts,
    onPageProducts,
    sortCriteria,
    filters,
    breadcrumbCategory,
    codeCategoryProduct,
    loadingListProduct,
    openFilterDrawer,
    criteriaFilter,
    rangeFilter,
  } = state;
  const {
    getCategory,
    setIdCategory,
    setCategoryProduct,
    getBreadcrumbCategory,
    setFilters,
    setPageProducts,
    setOnPageProducts,
    setSortCriteria,
    getProductList,
    setRequestList,
    handleOpenFilterDrawer,
    closeFilterDrawer,
    resetState,
    addCriteriaFilter,
    setCriteriaFilter,
    removeCriteriaFilter,
    replaceCriteriaFilter,
    removeRangeFilterMin,
    removeRangeFilterMax,
    replaceRangeFilterMin,
    replaceRangeFilterMax,
    setRangeFilter,
    clearFilters
  } = actions as Actions;
  const filterRangeLength = rangeFilter.length;
  const filterCriteriaLength = criteriaFilter.length + filterRangeLength;
  

  const getCategoryProduct = useCallback(() => {
    if (!idCategory) return;
    getCategory(idCategory);
  }, [idCategory]);

  const getBreadcrumb = useCallback(() => {
    if (!codeCategoryProduct) return;
    getBreadcrumbCategory(codeCategoryProduct);
  }, [codeCategoryProduct]);

  const getProducts = useCallback(
    (mainUrl: string, mainObject: string) => {
      if (!mainObject) return;
      if (mainObject !== "categoryproduct") return;
      if (!filters || !filters.length) return;
      // logger("getProducts", filters);
      getProductList(
        pageProducts,
        onPageProducts,
        filters,
        sortCriteria,
        criteriaFilter,
        mainUrl,
        rangeFilter
      );
    },
    [
      pageProducts,
      onPageProducts,
      filters,
      sortCriteria,
      criteriaFilter,
      rangeFilter,
    ]
  );

  const getDefaultFilters = useCallback(
    (currentRoute: ICurrentRoute | null) => {
      if (!currentRoute) return;

      if (!codeCategoryProduct) return;
      const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
      if (reqListRoute.page) setPageProducts(reqListRoute.page);
      if (reqListRoute.criteria && reqListRoute.criteria.length > 0)
        setCriteriaFilter(reqListRoute.criteria);
      if (reqListRoute.range && reqListRoute.range.length > 0)
        setRangeFilter(reqListRoute.range);
      setFilters([
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("hcodes", [codeCategoryProduct]),
      ]);
      if (reqListRoute.sortcriteria && reqListRoute.sortcriteria.length) {
        setSortCriteria(reqListRoute.sortcriteria[0]);
      }
    },
    [codeCategoryProduct]
  );

  const clearFilterCriteria = () => {
    clearFilters()
  };

  return useMemo(
    () => ({
      categoryProduct,
      idCategory,
      totalPagesProducts,
      totalProducts,
      productList,
      pageProducts,
      onPageProducts,
      sortCriteria,
      filters,
      breadcrumbCategory,
      codeCategoryProduct,
      loadingListProduct,
      getCategoryProduct,
      setIdCategory,
      setCategoryProduct,
      getBreadcrumb,
      getProducts,
      setPageProducts,
      setOnPageProducts,
      setFilters,
      setSortCriteria,
      setRequestList,
      getDefaultFilters,
      openFilterDrawer,
      handleOpenFilterDrawer,
      closeFilterDrawer,
      getBreadcrumbCategory,
      resetState,
      criteriaFilter,
      addCriteriaFilter,
      removeCriteriaFilter,
      replaceCriteriaFilter,
      filterCriteriaLength,
      clearFilterCriteria,
      rangeFilter,
      removeRangeFilterMin,
      removeRangeFilterMax,
      replaceRangeFilterMin,
      replaceRangeFilterMax,
    }),
    [
      filterCriteriaLength,
      openFilterDrawer,
      categoryProduct,
      idCategory,
      totalPagesProducts,
      totalProducts,
      productList,
      pageProducts,
      onPageProducts,
      sortCriteria,
      filters,
      breadcrumbCategory,
      codeCategoryProduct,
      loadingListProduct,
      criteriaFilter,
      getCategoryProduct,
      getBreadcrumb,
      getProducts,
      getDefaultFilters,
      rangeFilter,
    ]
  );
};

import { Box } from "@mui/material";
import { NoResult } from "components/elements/display/NoResult";
import { ProductWishlistDto } from "dto/product/productwishlist.dto";
import React from "react";
import { UserWishlistItem } from "./UserWishlistItem";


type Props = {
  objects: ProductWishlistDto[];
};

const UserWishlistList: React.FC<Props> = ({ objects }) => {
  const processItem = (object: ProductWishlistDto, index: number) => {
    if (!object) return null;
    return (
      <Box sx={{ py: 1 }} key={index}>
        <UserWishlistItem object={object} />
      </Box>
    );
  };
  if (!objects) return null;
  if (!objects.length) return <NoResult identifier="my_order" />;
  return (
    <Box>{objects.map((object, index) => processItem(object, index))}</Box>
  );
};

export { UserWishlistList };

import { IconType } from "components/elements/icon/IconContext";
import { MyIconButton } from "components/elements/icon/MyIconButton";
import { OfferFilterDto } from "dto/product/offerfilter.dto";
import { ProductDto } from "dto/product/product.dto";
import { CartDto } from "dto/sale/cart.dto";
import { useCart } from "hooks/useCart";
import { useProduct } from "hooks/useProduct";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: ProductDto;
};

const CartButton: React.FC<Props> = ({ object }) => {
  const { isProductOutOfStock } = useProduct();
  const { addFromCartProductPage, inCart } = useCart();
  const isInCart = inCart(
    CartDto.getId(
      object,
      object.defaultOfferObj ?? null,
      OfferFilterDto.arrOfferFilterToSelectedFilter(
        object.defaultOfferObj?.offerFilters
      )
    ) ?? ""
  );
  const handleClick = () => {
    if (isInCart) return;
    if (!object) return;
    if (!object.defaultOfferObj) return;
    if (
      !CommonTools.processObjectField(object, ["defaultOfferObj", "real_price"])
    )
      return;

    addFromCartProductPage(
      object,
      1,
      object.defaultOfferObj,
      OfferFilterDto.arrOfferFilterToSelectedFilter(
        object.defaultOfferObj?.offerFilters
      )
    );
  };

  if (!object) return null;
  if (!object._canspeedcart) return null;
  const disabled = isProductOutOfStock(object);
  return (
    <MyIconButton
      sx={{
        backgroundColor: isInCart ? "#E2DBA3" : "transparent",
        display: disabled ? "none" : "block",
        justifyContent: "center",
        alignItems: "center",
        ":hover": {
          backgroundColor: "#E2DBA3",
        },
      }}
      onClick={handleClick}
      typeIcon={IconType.CART}
      colorSvg={"#000"}
      disabled={disabled}
    />
  );
};

export { CartButton };

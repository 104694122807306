import React from "react";
import { IPage } from "interfaces/page.interface";
import { Box } from "@mui/material";
import { AdvertisementHomeTopBlock } from "components/static/advertisement/AdvertisementHomeTopBlock";
import { FAQList } from "components/static/faq/FAQList";
import { FAQType } from "tools/types/faqtype";
import { CategoryProductListHome } from "components/product/categoryproduct/home/CategoryProductListHome";
import { BlogListHome } from "components/static/blog/home/BlogListHome";
import { AboutUsHome } from "components/static/page/block/AboutUsHome";

import { useSeoInfo } from "hooks/useSeoInfo";

const HomePage: React.FC<IPage> = () => {
  useSeoInfo();
  return (
    <Box>
      <AdvertisementHomeTopBlock />
      <CategoryProductListHome />
      <AboutUsHome />
      <BlogListHome />
      <Box id="faq">
        <FAQList type={FAQType.HOME_PAGE} />
      </Box>
    </Box>
  );
};

export { HomePage };

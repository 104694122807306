import { Box, Grid, Skeleton } from "@mui/material";
import { NoResult } from "components/elements/display/NoResult";
import { BlogDto } from "dto/static/blog.dto";
import { useBlogCategory } from "hooks/useBlogCategory";
import React from "react";
import { BlogItem } from "./BlogItem";
import { SkeletonBlogList } from "components/elements/skeleton/blog/SkeletonBlogList";

const BlogList: React.FC = () => {
  const { blogObjects, totalBlogs, totalPagesBlogs, loadingBlog } =
    useBlogCategory();

  const processItem = (item: BlogDto, index: number) => {
    if (!item) return null;
    return (
      <Grid item xs={12} md={6} xl={index % 3 === 0 ? 12 : 6} key={index}>
        <BlogItem item={item} />
      </Grid>
    );
  };
  if (loadingBlog) return <SkeletonBlogList lines={3} />;
  if (!blogObjects) return null;
  if (totalBlogs === -1) return null;
  if (totalPagesBlogs === -1) return null;
  if (!totalBlogs) return <NoResult identifier="blog" />;
  return (
    <Grid container spacing={2}>
      {blogObjects.map((item: BlogDto, index: number) =>
        processItem(item, index)
      )}
    </Grid>
  );
};

export { BlogList };

import { Box, Typography } from "@mui/material";
import { OrderDto } from "dto/sale/order.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: OrderDto;
};

const PaymentLegalPerson: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  if (!object) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_fct_jur_company")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_fct_jur_company"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_fct_jur_idno")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_fct_jur_idno"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_fct_jur_othercode")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_fct_jur_othercode"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_fct_jur_address")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_fct_jur_address"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_fct_jur_iban")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_fct_jur_iban"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_fct_jur_bank")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_fct_jur_bank"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_fct_jur_phone")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_fct_jur_phone"])}
        </Typography>
      </Box>
    </Box>
  );
};

export { PaymentLegalPerson };

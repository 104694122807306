import { Box } from "@mui/material";
import { BottomNavigatorLevel } from "components/static/menu/bottom/BottomNavigatorLevel";
import React from "react";

const MenuBlock: React.FC = () => {
  return (
    <Box sx={{ width: "19vw", maxWidth: "200px" }}>
      <BottomNavigatorLevel level={1} idParent="0" />
    </Box>
  );
};

export { MenuBlock };

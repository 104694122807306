import { Box, Typography } from "@mui/material";
import { useCategoryProduct } from "hooks/useCategoryProduct";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Image } from "components/elements/display/Image";
import { VideoFile } from "components/elements/display/VideoFile";

const CategoryTopBlock: React.FC = () => {
  const { categoryProduct } = useCategoryProduct();
  const styleBlock = {
    width: "100%",
    height: "100%",
    boxSize: "content-box",
    overflow: "hidden",
  };

  const processImage = () => {
    if (!categoryProduct) return null;
    if (
      !CommonTools.processObjectField(categoryProduct, [
        "_galleryDefault",
        "cdnurl",
      ])
    )
      return null;
    return (
      <Image
        url={CommonTools.processObjectField(categoryProduct, [
          "_galleryDefault",
          "cdnurl",
        ])}
        typeMeasure="percentage"
        sizeInPercentage={{ width: "105%", height: "105%" }}
        withLinK={false}
        style={{ margin: 0, padding: 0, objectFit: "cover" }}
        cutImageHeight={570}
        cutImageWidth={2500}
      />
    );
  };

  const processMedia = () => {
    if (!categoryProduct) return null;
    if (
      CommonTools.processObjectField(categoryProduct, [
        "_attachmentDefault",
        "cdnurl",
      ])
    ) {
      return (
        <Box className="videoBoxWidth">
          <VideoFile
            url={CommonTools.processObjectField(categoryProduct, [
              "_attachmentDefault",
              "cdnurl",
            ])}
            //   light={processImage() !== null ? processImage() : false}
            controls={false}
            width="100%"
            height="100%"
          />
        </Box>
      );
    } else {
      return processImage();
    }
  };

  if (!categoryProduct) return null;
  if (
    !CommonTools.processObjectField(categoryProduct, [
      "_attachmentDefault",
      "cdnurl",
    ]) &&
    !CommonTools.processObjectField(categoryProduct, [
      "_galleryDefault",
      "cdnurl",
    ])
  )
    return null;
  return (
    <Box className="categoryTopBlock">
      <Box className="categoryTopBlockBox">
        <Box className="addBlocks">
          {processMedia()}
          <Box className="categoryTopBlockBoxText">
            <Box className="flexJustifyCenter">
              <Typography className="categoryTopBlockBoxTextStyle">
                {CommonTools.processObjectField(categoryProduct, ["_name"])}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { CategoryTopBlock };

import { Box, List, Skeleton } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { NavigatorDto } from "dto/static/navigator.dto";
import { useList } from "hooks/useList";
import React from "react";
import { NavigatorService } from "services/static/navigator.service";
import { NavigatorSection } from "tools/types/navigatorsection";
import { Status } from "tools/types/status";

import { DrawerNavigatorItem } from "./DrawerNavigatorItem";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

const service = new NavigatorService();
const DrawerNavigator: React.FC = () => {
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  const [loading, objects] = useList<NavigatorDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("navigatorsection", [
          NavigatorSection.MAIN_DRAWER.toString(),
        ]),
      ],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    ),
    [],
    true,
    `menu-drawer`
  );

  const processItem = (item: NavigatorDto, index: number) => {
    if (!item) return null;
    return <DrawerNavigatorItem key={index} item={item} />;
  };
  if (loading)
    return (
      <Box sx={{ width: "30%", py: 2 }}>
        <Skeleton />
      </Box>
    );
  if (!objects) return null;
  if (!objects.length) return null;
  return (
    <List>
      {objects.map((item: NavigatorDto, index: number) =>
        processItem(item, index)
      )}
    </List>
  );
};

export { DrawerNavigator };

import { Context, Actions, StateResource } from "contexts/product.context";
import { ProductDto } from "dto/product/product.dto";
import { SelectedProductFilterDto } from "dto/product/productfilter.dto";

import { useMemo, useContext, useCallback } from "react";
import { CommonTools } from "tools/utils/common.tool";


type Props = () => {
  get: (id: string) => void;
  resetState: () => void;
  getProductFilters: () => void;
  getOffers: () => void;
  setQuantity: (quantity: number | string) => void;
  selectFilter: (filter: SelectedProductFilterDto) => void;
  getSelectedOffer: () => void;
  isProductOutOfStock: (object?: ProductDto) => boolean;
} & StateResource;

export const useProduct: Props = () => {
  const { state, actions } = useContext(Context);

  const {
    product,
    loadingProduct,
    productFilterObjects,
    idProduct,
    offerObjects,
    selectedOffer,
    quantity,
    selectedFilters,
    loadingOffer,
    loadingProductFilter,
  } = state;
  const {
    getProduct,
    resetState,
    getFilterProductObjects,
    getOfferObjects,
    setQuantity,
    selectFilter,
    setSelectedOffer,
  } = actions as Actions;

  const get = useCallback((id: string) => {
    if (!id) return;
    getProduct(id);
  }, []);

  const getProductFilters = useCallback(() => {
    if (!idProduct) return;
    getFilterProductObjects(idProduct);
  }, [idProduct]);

  const getOffers = useCallback(() => {
    if (!idProduct) return;
    getOfferObjects(idProduct);
  }, [idProduct]);

  const getSelectedOffer = useCallback(() => {
    // logger("newOffers--------------selectedFilters", selectedFilters);
    if (loadingOffer) return;
    if (loadingProductFilter) return;
    setSelectedOffer(offerObjects, quantity, selectedFilters);
  }, [
    offerObjects,
    quantity,
    selectedFilters,
    loadingOffer,
    loadingProductFilter,
  ]);

  const isProductOutOfStock = (object?: ProductDto) => {
    if (!object) return true;
    if (!object.defaultOfferObj) return true;
    if (
      !CommonTools.processObjectField(object, ["defaultOfferObj", "real_price"])
    )
      return true;
    return false;
  };

  return useMemo(
    () => ({
      get,
      product,
      loadingProduct,
      resetState,
      productFilterObjects,
      idProduct,
      getProductFilters,
      offerObjects,
      getOffers,
      selectedOffer,
      quantity,
      setQuantity,
      selectedFilters,
      selectFilter,
      getSelectedOffer,
      loadingOffer,
      loadingProductFilter,
      isProductOutOfStock,
    }),
    [
      get,
      product,
      loadingProduct,
      productFilterObjects,
      idProduct,
      getProductFilters,
      offerObjects,
      getOffers,
      selectedOffer,
      quantity,
      selectedFilters,
      getSelectedOffer,
      loadingOffer,
      loadingProductFilter,
    ]
  );
};

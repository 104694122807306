import IGeneralRepository from "interfaces/irepository.interface";
import GeneralRepository from "../general.repository";

export class SystemRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("");
  }

  changeInfo = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/chageallinfodata`;
    return await this.postAxios(url, data, cb, cbParams);
  };

  getBySlug = async (slug: string, cb?: any, cbParams?: any) => {
    const url = `/slug/find?url=${slug}`;
    return await this.getObject(url, cb, cbParams);
  };

  getSeoInfo = async (suffix?: string, cb?: any, cbParams?: any) => {
    let url = `/seoinfo`;
    if (suffix) {
      url = url.concat(`?suffixurl=${suffix}`);
    }
    return await this.getObject(url, cb, cbParams);
  };
}
